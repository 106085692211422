<template>
 <div  id="chart">
        <Bar id="my-chart-id" :data="data" :options="options"/>
 </div>
</template>

<script>
 import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement,CategoryScale, LinearScale } from 'chart.js'
  ChartJS.register(Title, Tooltip, Legend,BarElement, CategoryScale, LinearScale)
    export default{
        name:'BarChat',
 components :{Bar},
 props:["data","options"]

    }
</script>