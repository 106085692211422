import store from '@/store';
import Axios from './caller';

let getIndicateurs = (id) => {
    return Axios.post('/getIndicateurs',{
        id: id
    })
}

let getPerformances = () => {
    return Axios.get('/getPerformances')
}

let deletePerform = (index) => {
    return Axios.delete('/deletePerform/'+index)
}

let createPerform = (financiers, resultats,tresorerie,infos, userId) =>{
    return Axios.post('/createPerform',{
        userId,
        financiers: {
            actifs_immobilise: financiers['Actif immobilisée'],   
            capitaux_propres:  financiers['Capitaux propres'],
            actifs_circulant:financiers['Actifs circulant'],
            dettes_financieres:financiers['Dettes financières'],
            tresorerie_actif:financiers['Trésorerie actif'],
            passif_circulants:financiers['Passif circulants'],
            tresorerie_passif:financiers['Trésorerie passif'],
            total_passif:financiers['Total passif'],
            total_actif:financiers['Total actif'],
            creance:financiers['Créances'],
            stock:financiers['Stocks'],
            fournisseur:financiers['Fournisseur']
        },
        resultats:{
            valeur_ajoute: resultats['Valeur Ajouté'],
            achat_horsTaxe: resultats['Achat de stock HT'],
            charge_salariales: resultats['Charge Salariale'],
            variation_stocks:  resultats['Variation de Stock'],
            charge_financiere: resultats['Charge Financière'],
            chiffre_affaire: resultats['Chiffre d’affaire'],
            ebe: resultats['Excédent Brut d’Exploitation'],
            rex: resultats['Résultat d’exploitation net'],
            amortissement_et_provisions_exploitation:resultats['Amortissement et provisions d’exploitation'],
            rn: resultats['Résultat net'],
            resultat_exceptionnel: resultats['Résultat Exceptionnel'],
            amortissement_exceptionnel:resultats['Amortissement Exceptionnel'],
            impot_societe:resultats['Impot'],
            rf: resultats['Résultat financier'],
            amortissement_financier:resultats['Amortissement financier'],
            ebit:resultats['EBIT'],
            cmpd:infos['CMPD'],
            effectif:infos['Effectifs'],
            taux_impot:infos['Taux Impot'],
            taux_tva:infos["Taux TVA"],
            exercice:infos["Année de l’exercice"]
        },

        tresorerie:{
            t_exploitation:tresorerie["Flux de trésorerie d'exploitation"],
            t_investissement:tresorerie["Flux de trésorerie d'investissement"],
            t_financement:tresorerie["Flux de trésorerie de financement"],
            variation_creance:tresorerie["Variations compte créance et assimilés"],
            variation_fournisseur:tresorerie["Variations compte fournisseur et assimilés"]
        }
    });
}

let fetchDatas =(val1) => {
 return  Axios.post('/getGraphicData', {
        id:store.getters.getUser.id,
        indicateur_id: val1
    });
}

let stats =() => {
    return  Axios.post('/getDashStat', {
           id:store.getters.getUser.id,
       });
   }
   


export const performance = { deletePerform, createPerform, getPerformances, getIndicateurs,fetchDatas ,stats};