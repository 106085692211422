<template>
     
            <div class="app-page-title">
                <div class="page-title-wrapper">
                    <div class="page-title-heading">
                        <div class="page-title-icon">
                            <i class="fa fa-question" aria-hidden="true"></i>
                        </div>
                        <div>
                            Liste de vos questionnaires
                        </div>
                    </div>
                    <div class="page-title-actions">
                        <div class="d-inline-block dropdown">
                            <router-link class="btn btn-success" :to="{name:'createQuestionnaire'}">
                                <span class="btn-icon-wrapper pr-2 opacity-7">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </span>
                                Nouveau Questionnaire
                            </router-link>
                        </div>
                    </div>    
                </div>
            </div>
            <table class="table">
                <thead class="text-center">
                <tr>
                    <th>#</th>
                    <th>Libellé </th>
                    <th>Date de création </th>
                    <th colspan="2" >Actions</th>
                </tr>
                </thead>
                <tbody class="text-center">
                <tr v-for="(item, index) in data" :key="item.id">
                    <th>{{ index+1 }}</th>
                    <td>{{ item.reference }}</td>
                    <td> {{ moment(item.created_at).format('YYYY-MM-DD à HH:mm:ss') }}</td>
                    
                    <td>
                        <button class="btn-transition btn btn-outline-info">
                            Visualiser <i class="ml-2  ml-2 fa fa-eye" aria-hidden="true"></i>
                        </button>
                    </td>
                    <td>
                        <button class="btn-transition btn btn-outline-danger">
                            Supprimer <i class="ml-2 fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
      
</template>

<script>

import {rh} from '@/services'
import store from '@/store'
import moment from 'moment';

export default {

    data() {
        return {
            data: []
        }
    },
    mounted(){
        rh.listeQuestionnaire(store.getters.getUser.id).then((response) =>{
            this.data = response.data.questionnaire
            console.log(response)
            console.log(this.data)
        })
    },

    methods:{
        moment(date) {
                return moment(date);
            }
    }
}   
</script>

<style scoped>

</style>