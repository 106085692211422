<template>
<meta name="csrf-token" content="{{ csrf_token() }}">
  <div v-if="afficherComposant()">
    <router-view></router-view>
  </div>
  <MainContent v-else>
    <router-view></router-view>
  </MainContent>
</template>

<script>

import store from '@/store'
import MainContent from './components/DashboardComponent/MainContent.vue';
export default {
  name: 'App',
  data() {
    return {
      routesPermises:["/", "/connexion-entreprise", "/connexion","/inscription","/connecter","/connecter",],
    }
  },
  components: {
    MainContent
  },
  methods: {
    afficherComposant(){
      return this.routesPermises.includes(this.$route.path) || this.$route.name == "Questionnaire" || this.$route.name == "notFound"
    },
  },
  mounted(){
    const expiration = 6 * 60 * 60 * 1000;
    setTimeout(() => {
      store.commit('logout')
    }, expiration);
  }
}
</script>


