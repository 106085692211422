/* eslint-disable no-unused-vars */

import Axios from './caller';
import store from '@/store';

// Axios.defaults.withCredentials = true;

let register = (entreprise) =>{
    return Axios.post('/register',{
        denomination: entreprise.denomination,
        adresse: entreprise.adresse,
        rccm: entreprise.rccm,
        ifu: entreprise.ifu,
        taille: entreprise.taille,
        email: entreprise.email,
        password: entreprise.password
    });
}

let connexionEntreprise = (user) => {
    return Axios.post('/loginEntreprise',{
        email: user.email,
        password: user.password
    });
}

let loginEmploye = (user) => {
    return Axios.post('/loginEmploye',{
        email: user.email,
        password: user.password,
    });
}


let updateProfileEntreprise = (entreprise, id) =>{
    return Axios.patch('/updateProfileEntreprise',{
        denomination: entreprise.denomination,
        adresse: entreprise.adresse,
        rccm: entreprise.rccm,
        ifu: entreprise.ifu,
        taille: entreprise.taille,
        email: entreprise.email,
        password: entreprise.password,
        userId: id
    })
};

let updateProfileEmploye = (employe, id) =>{
    return Axios.patch('/updateProfileEmploye',{
        nom: employe.nom,
        prenom: employe.prenom,
        sexe: employe.sexe,
        naissance: employe.naissance,
        telephone: employe.telephone,
        adresse: employe.adresse,
        email: employe.email,
        password: employe.password,
        userId: id
    })
};


let downloadModel = ()=>{
    return Axios.get('/downloadModel',{
        responseType: 'blob'
    });
}


export const account = { updateProfileEntreprise, updateProfileEmploye, register, connexionEntreprise, loginEmploye, downloadModel};