<template>
        <div class="app-sidebar sidebar-shadow">
            <div class="app-header__logo">
                <div class="logo-src"></div>
                <div class="header__pane ml-auto">
                    <div>
                        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                            <span class="hamburger-box">
                                <span class="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="app-header__mobile-menu">
                <div>
                    <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
            <div class="app-header__menu">
                <span>
                    <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                        <span class="btn-icon-wrapper">
                            <i class="fa fa-ellipsis-v fa-w-6"></i>
                        </span>
                    </button>
                </span>
            </div>    <div class="scrollbar-sidebar">
                <div class="app-sidebar__inner">
                    <ul class="vertical-nav-menu">
                        <li class="app-sidebar__heading">Tableau de Bord</li>
                        <li>
                            <router-link ref="bouton1" :class="{'mm-active': boutonActif==1}" @click="selectBtn(1)" to="/dashbord-entreprise">
                                <i class="metismenu-icon pe-7s-display2"></i>
                                Tableau de Bord
                            </router-link>
                        </li>
                        <li class="app-sidebar__heading">Evaluations</li>
                        <li>
                            <a href="#">
                                <i class="metismenu-icon pe-7s-graph1"></i>
                                Performance Financière
                                <i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                            </a>
                            <ul>
                                <li>
                                    <router-link ref="bouton2" :class="{'mm-active': boutonActif==2}"  @click="selectBtn(2)"  to="/dashbord-new-performance">
                                        <i class="metismenu-icon"></i>
                                        Lancer
                                    </router-link>
                                </li>
                                <li>
                                    <router-link ref="bouton3" :class="{'mm-active': boutonActif==3}"  @click="selectBtn(3)"  to="/rapport-performance">
                                        <i class="metismenu-icon">
                                        </i>Rapports
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">
                                <i class="metismenu-icon pe-7s-users"></i>
                                Ressources Humaines
                                <i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                            </a>
                            <ul>
                                <li>
                                    <router-link ref="bouton4" :class="{'mm-active': boutonActif==4}"  @click="selectBtn(4)"  to="/new-rh">
                                        <i class="metismenu-icon">
                                        </i>Lancer
                                    </router-link>
                                </li>
                                <li>
                                    <router-link ref="bouton5" :class="{'mm-active': boutonActif==5}" @click="selectBtn(5)"  :to=" {name:'listQuestionnaire'} ">
                                        <i class="metismenu-icon">
                                        </i>Questionnaires
                                    </router-link>
                                </li>
                                <li>
                                    <router-link ref="bouton6" :class="{'mm-active': boutonActif==6}" @click="selectBtn(6)"  to="#">
                                        <i class="metismenu-icon">
                                        </i>Rapports
                                    </router-link>
                                </li>
                                <li>
                                    <router-link ref="bouton7" :class="{'mm-active': boutonActif==7}" @click="selectBtn(7)"  to="/employe">
                                        <i class="metismenu-icon">
                                        </i>Employes
                                    </router-link>
                                    <router-link ref="bouton8" :class="{'mm-active': boutonActif==8}"  @click="selectBtn(8)" to="/departement">
                                        <i class="metismenu-icon">
                                        </i>Département
                                    </router-link>
                                </li>
                                <li>
                                    <router-link ref="bouton9" :class="{'mm-active': boutonActif==9}" @click="selectBtn(9)"  to="/poste">
                                        <i class="metismenu-icon">
                                        </i>Postes
                                    </router-link>
                                </li>
                               
                            </ul>
                        </li>
                        <li class="app-sidebar__heading">Profile</li>
                        <li>
                            <router-link ref="bouton11" to="/dashbord-profile-entreprise" :class="{'mm-active': boutonActif==11}" @click="selectBtn(11)">
                                <i class="metismenu-icon pe-7s-user"></i>
                                {{ user.denomination }}
                            </router-link>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </div>  
   
</template>

<script>
import store from '@/store'
export default {
    name:"SideBar",
    data() {
        return {
            user:'',
            boutonActif:null
        }
    },
    methods: {
        selectBtn(index){
            this.boutonActif=index;
        }
    },
    mounted() {
            this.user = store.getters.getUser;
            
        },
}
</script>