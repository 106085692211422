<template>
 <div  id="chart"  >
        <Line id="my-chart-id" :data="data" :options="options"/>
 </div>
</template>

<script>
 import { Line } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement,CategoryScale, LinearScale } from 'chart.js'
  ChartJS.register(Title, Tooltip, Legend,LineElement, PointElement, CategoryScale, LinearScale)
    export default{
 components :{Line},
 props:["data","options"]

    }
</script>