<template>

<div>
    <div v-on:click="toggleline" class="btn btn-outline-primary mr-2 mb-2"> Ligne </div>
    <div v-on:click="togglebar" class="btn btn-outline-primary mr-2 mb-2"> Baton </div>
    <div v-on:click="togglepie" class="btn btn-outline-primary mr-2 mb-2"> Circulaire</div>


            <div class="card" v-if="linebool">
              <LineGraph 
                :options="chartOptions"
                :data="chartData"
              />
            </div>
               
            <div  class="card" v-if="barbool">
              <BarGraph 
                :options="chartOptions"
                :data="chartData"
              />
            </div>

            <div  class="card pie" v-if="piebool">
              <PieGraph 
                :options="ChartOptions"
                :data="PieData"
              />
            </div>
            
    </div>  
</template>
  
  <script>
 
  import LineGraph from '@/components/Performance/Graphe/LineChart.vue'
    import BarGraph from '@/components/Performance/Graphe/BarChart.vue'
    import PieGraph from '@/components/Performance/Graphe/PieChart.vue'


  export default {
    name: 'ModalChart',
    components: { 
      LineGraph ,
      BarGraph,
      PieGraph
      },
    data() {
      return {
        chartData: {
          labels: this.labels,
          datasets: [ 

            { 
              labels:'Data One',
              backgroundColor:'#00f', 
              borderColor:'blue',
              data: this.donnes ,
              fill: false,
            tension: 0.4,
            } 
          
          ],
          
        },
        PieData: {
          labels: this.labels,
          datasets: [ 

            { 

              backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#DA1B16', '#DB1B16', '#DE1316', '#DD1216', '#D12516', '#D45C31'],
              data: this.donnes ,
            } 
          
          ]
          
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio:false,
          scales: {
          x: {
            display:false,
          grid: {
            display: false
          },
          ticks: {
                    display: false
                }
        },
        y: {
          display:false,
          grid: {
            display: false
          },
          ticks: {
                    display: false
                }

        },
        },
          plugins:{
            title:{
              display:true,
              text:this.titre+' (Evolution des dix derniers)',
              font:{
                size:12
              }
            }
          }

        },
       
        linebool:true,
        barbool:false,
        piebool:false
      }
    },
    props:["donnes","labels","titre"],
    methods:{
      toggleline(){
        this.linebool=true;
        this.barbool=false;
        this.piebool=false
      },
      togglebar(){
        this.linebool=false;
        this.barbool=true;
        this.piebool=false
      },
      togglepie(){
        this.linebool=false;
        this.barbool=false
        this.piebool=true
      }
    }
   
  }
  
  </script>

  <style scoped>
  
.pie{
  max-height: 250px;
  display:flex;
  justify-content:center;
  align-items:center;
}

  </style>