<template>

    <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
        <NavBar></NavBar>
        <div class="app-main">
            <SideBar v-if="type == 'entreprise' "></SideBar>
            <SidebarEmploye v-else></SidebarEmploye>
            <div class="app-main__outer">
                <div class="app-main__inner">
                    
                        <div v-if="this.$route.path!='/dashbord-entreprise'" class="retour">
                           <a type="button" @click="this.$router.back()"><i class="fa fa-fw" aria-hidden="true"></i></a>
                        </div>
                   
                    <slot></slot>
                </div>
                    <FooTer></FooTer>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import { useToast } from "vue-toastification"; // POSITION
import NavBar from './NavBar.vue';
import SideBar from './SideBar.vue';
import SidebarEmploye from './SidebarEmploye'
import FooTer from './FooTer.vue';
export default {
    name:"MainContent",
    data(){
        return {
            type: store.getters.getType
        }
    },
    components:{
        NavBar,
        SideBar,
        FooTer,
        SidebarEmploye
    },
    mounted() {
        const script1 = document.createElement('script');
        script1.src = 'templateDashboard/assets/scripts/main.js';
        script1.type='text/javascript';

        
        // Ajoutez l'élément <script> au corps de la page
 

  document.body.appendChild(script1);
  //window.alert('ok main')
  const toast = useToast();
    //   toast.success('Heureux de vous revoir '+store.getters.getUser.denomination)
    toast.success('Bienvenu')
    },

}
</script>
<style scoped>
@import url('../../../public/templateDashboard/assets/css/main.css');
.retour{
    text-align: left;
    font-size: 25px;
    color: #1d4851;
}

</style>