
<template>
  <div class="container bootstrap snippets bootdey">
    <div class="login-page">
    <div class="login-form">
        <div >
            <img src="logokhc.png" class="logo user-avatar">
        </div>
    <h1>K-PERFORM</h1>
    <form @submit.prevent="connexion" method="post">
      <span class="error" v-if="faux" >
                    {{ errors }}
              </span>
    <div class="form-content">
    <div class="form-group">
    <input type="email" v-model="state.employe.email" class="form-control input-underline input-lg" placeholder="Email">
    </div>
    <div class="form-group mt-2">
    <input type="password" v-model="state.employe.password" class="form-control input-underline input-lg" placeholder="Mot de passe">
    </div>

    <button type="submit"  class="btn btn-info btn-lg">
    Se Connecter
    </button>   
    </div>
    
    </form>
    </div>
    </div>
  </div>
</template>

<script>
    import { account } from '@/services';
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers } from '@vuelidate/validators'
    import {reactive, computed} from 'vue';
    import store from '@/store'
  
  export default {
      data() {
          return {
            errors: "",
            faux: false,
          }
      },
      setup(){
        const state =  reactive({
          employe:{
              email: '',
              password: '',
            },
        })
  
        const rules = computed(() => {
          return {
            employe: {
                  email: {
                    required: helpers.withMessage("Ce champ est requis", required),
                    email: helpers.withMessage("Email invalid ou déjà utiliser", email)
                  },
                  password: {
                    required: helpers.withMessage("Ce champ est requis", required)
                  },
                }
          }
        })
        const v$ = useVuelidate(rules, state)
        return {
          state,
          v$
        }
      },
      computed:{
        errorLength(){
          return this.errors.length
        }
      },
      methods:{
        connexion: function(){
          this.v$.$validate()
          account.loginEmploye(this.state.employe).then((response) => {
                console.log(response)
                const token = response.data.token;
                const type = response.data.type;
                const user = response.data.user;
                store.commit('setToken', token)
                store.commit('setType', type)
                store.commit('setUser', user)
                this.$router.push('/dashboard-employe')
            }).catch((error) => {
              if (error.response && error.response.status === 401) {
                this.faux = true
                this.errors = error.response.data.message
              }
            })
        }
      },
    }
  
  </script>
  
  <style scoped>

.inscription-btn{
  color: black;
}

.login-page{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    text-align: center;
    color: #fff;

    width: 100%;
    
  height: 100%;
  background-image: url('performance.jpg');

  background-size: cover;
  background-attachment: fixed;
}


.logo{
    border-radius: 50%;
    box-shadow: #222 3px 3px 3px 0px;
}

.user-avatar {
    width: 185px;
    height: 185px;
}
.login-page h1 {
    font-weight: 300;
    margin-top:20px;
    margin-bottom:20px;
}

.login-page .login-form .form-content {
    padding: 0;
}
.login-form{
    background-color:rgba(29, 72, 81,.7);
    border-radius: 20px;
    width:35%;
    height: auto;
    padding: 35px;
    margin: auto;
    margin-top: 5px;
    text-align: center;
    backdrop-filter: blur(10px);
}

.form-content{
  width: 90%;
  margin: auto;
 
}

.login-page .login-form .form-content .input-underline {
    background: 0 0;
    border: none;
    box-shadow: none;
    border-bottom: 2px solid rgba(255,255,255,.4);
    color: #fff;
    border-radius: 0;
    margin-top: 5%;
    margin-bottom: 10%;
}
.login-page .login-form .form-content .input-underline:focus {
    border-bottom: 2px solid #fff;
}    

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 0;
}

.btn-info{
    border-radius: 50px;
    box-shadow: 0 0 0 2px rgba(255,255,255,.8)inset;
    color: rgba(255,255,255,.8);
    background: 0 0;
    width: 100%;
    margin-top: 15px;
    border-color: transparent;
    font-weight: 400;
}
.btn-info:hover{
  background-color: #eb5d1e;
  border: white;
}
a{
    text-decoration: none;
    border-radius: 50%;
}
input[type='email']::-webkit-input-placeholder, input[type='password']::-webkit-input-placeholder, input[type='number']::-webkit-input-placeholder, input[type='text']::-webkit-input-placeholder{ 
    color:    #fff;
}
input[type='email']:-moz-placeholder, input[type='password']:-moz-placeholder, input[type='number']:-moz-placeholder, input[type='text']:-moz-placeholder { 
    color:    #fff;
}
input[type='email']::-moz-placeholder, input[type='password']::-moz-placeholder, input[type='number']::-moz-placeholder, input[type='text']::-moz-placeholder { 
    color:    #fff;
}
input[type='email']:-ms-input-placeholder, input[type='password']:-ms-input-placeholder,input[type='number']:-ms-input-placeholder,input[type='text']:-ms-input-placeholder { 
    color:    #fff;
}
</style>