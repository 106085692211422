<template>
    <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="updateModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="updateModalLabel">Modifier un departement</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="modifierDepartement">
                    <div class="modal-body">
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="position-relative form-group"><label for="libelle"  class="form-label">Libellé</label><input name="libelle"  id="libelle" v-model="model.departement.libelle" placeholder="Ex: Marketing..." type="text" class="form-control" required></div>
                            </div>
                            
                        </div>                    
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" ref="close" data-dismiss="modal">Fermer</button>
                        <button type="submit" class="btn btn-primary" >Enregistrer</button>
                    </div>
                </form>           
            </div>
        </div>
    </div>
</template>

<script>
import { integer } from '@vuelidate/validators'
import { rh } from '@/services';
export default {
    name:'updateDepartement',
    props:{
        id:integer
    },
    data() {
        return {

            model:{
                departement:{
                }
            },
        }
    },
    methods: {
        modifierDepartement(){
            rh.updateDepartement(this.id,this.model.departement).then(
               
                this.$refs.close.click(),
                this.$emit('modifierDepartement')
            )
        }
    },
   
    watch: {
        id(){
            rh.fetchdepartement(this.id).then((response)=>{
                this.model.departement=response.data.departement
            })
        }
    },
    

}
</script>

<style>

</style>