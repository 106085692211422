<template>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Ajouter un Poste</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="creerPoste">
                    <div class="modal-body">
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="position-relative form-group"><label for="libelle" class="form-label">Libellé</label><input name="libelle"  id="libelle" v-model="model.poste.libelle" placeholder="Ex: Marketing..." type="text" class="form-control" required></div>
                            </div>
                            
                        </div> 
                        <div class="form-row">
                            <div class="col-md-12">
                                    <div class="position-relative form-group"><label for="departement" class="">Departement</label>
                                        <select type="select" id="departement" name="departement" v-model="model.poste.departement_id" class="custom-select form-select" required>
                                                <option disabled selected value="">Selectionner le departement</option>
                                                <option v-for="item in departements" :key="item.id"  :value="item.id">{{item.libelle}}</option>                                            
                                        </select>
                                    </div>               
                                </div>
                        </div> 
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="position-relative form-group"><label for="description" class="form-label">Description</label><input name="description"  id="description" v-model="model.poste.description" placeholder="Ex: lorem ipsum" type="text" class="form-control" required></div>
                            </div>
                            
                        </div>                    
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" ref="close" data-dismiss="modal">Fermer</button>
                        <button type="submit" class="btn btn-primary" >Enregistrer</button>
                    </div>
                </form>           
            </div>
        </div>
    </div>
</template>

<script>
import { rh } from '@/services';
import store from '@/store';
export default {
    name:'CreateDepartement',
data() {
    return {
        userId:store.getters.getUser.id,
        departements:[],
        model:{
            poste:{
                libelle:'',
                description:'',
                departement_id:'',
            }
        },
    }
},

methods: {
    creerPoste(){
        console.log(this.model.poste)
        rh.newPoste(this.model.poste).then((response)=>{
            console.log(response.data)
            this.$refs.close.click(),
            this.$emit('updatePoste')
        })
        this.model.poste.libelle = ""
        this.model.poste.description = ""
        this.model.poste.departement_id = ""
    },
    
},
mounted() {
    rh.allDepartement(this.userId).then((response)=>{
        this.departements=response.data.departements 
    })
},
}
</script>

<style>

</style>