<template>
    <form @submit.prevent="submit" method="post">
        <div class="body">
            <span class="titre">
                <h1> Formulaire d'évaluation de {{ employe.nom }} {{ employe.prenom }}  </h1>
            </span>
            <div class="card contenu-questionnaire">
                <h3>Questionnaire </h3>
                <div v-for="(item) in questionnaire.questionnaire_rubriques" :key="item.id" class="card">
                    <div class="row">
                        <div class="col text-left"> <h4> {{ item.rubrique.libelle }} </h4></div>
                    </div>
                    <span v-for="(question, index) in item.questions" :key="question.id" class="ctn-qts">
                        <div class="qts1">
                            <p> <strong> {{ index+1 }}-</strong> {{ question.libelle }} </p>
                            <div v-if="question.type_question_id == 1">
                                <textarea v-model="reponses[question.id]" class="form-control mb-2" cols="10" rows="3"></textarea>
                            </div>
                            <div class="form-check" v-if="question.type_question_id == 2">
                                <div v-for="reponse in question.choix_possibles" :key="reponse.id">
                                    <input :value="reponse.reponse" v-model="reponses[question.id]" class="form-check-input" name="reponseChoix" type="radio">
                                    <label class="form-check-label" > {{ reponse.reponse }} </label>
                                </div>
                            </div>
                            <div v-if="question.type_question_id == 3" class="qts2">
                                <input v-model="reponses[question.id]" class="form-control text-right" type="number" />
                            </div>
                        </div>
                    </span>
                </div>
                <button type="submit"  class="btn btn-success">Soumettre</button>
            </div>
            
        </div>
    </form>
</template>

<script>
import { integer,alphaNum } from '@vuelidate/validators'
import { rh } from '@/services'
import store from '@/store'

export default {
    name:'updateDepartement',
    props:{
        quest_id:integer,
        token: alphaNum,
        employe_id: integer
    },

    data(){
        return{
            questionnaire: [],
            reponses: [],
            employe:[],
            userId: store.getters.getUser.id
        }
    },

    mounted(){
        rh.getQuestionnaire(this.$props.quest_id, this.userId).then((response) => {
            this.questionnaire = response.data.questionnaire
            console.log(response);
        })
        .catch((error) => {
            console.log(error);
        })
        rh.fetchEmploye(this.$props.employe_id).then((response) => {
            this.employe = response.data.employe
            console.log(response);
        })
        .catch((error) => {
            console.log(error);
        })
    },
    methods: {
        submit(){
            console.log(this.reponses);
        }
    },
}
</script>

<style scoped>
.body{
    background-color: #d1d6d48e;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 1200px;
}

.titre{
    background-color: beige;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid #c2b9b9;
}
h3{
    padding: 2rem;
}
h4{
    padding-top: 1rem;
    padding-right: 2rem;
}
.ctn-qts{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-top: 5px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 5px;
    border-top: solid #c2bcbc9d 2px;
}
.qts1{
    width: 90%;
}
.qts2{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
}
.card{
    height: auto;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;

}
.contenu-questionnaire{
    background-color:blue ;
    width: 80%;
    margin-top: 30px;
    padding: 30px0;
    border:solid #000 1px 2px 3px 4px;
    background-color: #fff;
    display: flex;


    
}


</style>