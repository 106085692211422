<template>
  <div class="container bootstrap snippets bootdey">
    <div class="login-page">
    <div class="login-form pt-2 pb-3">
        <div >
            <img src="../../../public/logokhc.png" class="logo user-avatar">
        </div>
    <h1>K-PERFORM</h1>
    <form @submit.prevent="validationForm" method="post">
      
    <div class="form-content">

      <div v-if="currentState == 1" id="state1">
                    
                    <div class="form-group">
                      <input type="text" v-model="state.entreprise.denomination" class="form-control input-underline input-lg"  placeholder="Denomination">
                    </div>
                    <span class="error  mt-0" v-if="v$.entreprise.denomination.$error" >
                      {{ v$.entreprise.denomination.$errors[0].$message }}
                    </span>
                  
                    <div class="form-group">
                      <input type="email"  v-model="state.entreprise.email" class="form-control input-underline input-lg" placeholder="Email de votre Entreprise">
                    </div>
                    <span class="error  mt-0" v-if="v$.entreprise.email.$error" >
                        {{ v$.entreprise.email.$errors[0].$message }}
                    </span>

                    <div class="form-group">
                      <input type="text"  v-model="state.entreprise.ifu" class="form-control input-underline input-lg" placeholder="N° IFU">
                    </div>
                   
                    <div class="form-group">
                      <input type="text"  v-model="state.entreprise.rccm" class="form-control input-underline input-lg" placeholder="Registre de Commerce">
                    </div>

                  <div class=" pt-1">
                    <button class="btn btn-info btn-lg" @click="goToStep(2)" type="button" >Suivant </button>
                  </div>
        </div>
        <div v-if="currentState == 2">   

                  <div  class="form-group">
                        <select v-model="state.entreprise.taille"  class="form-select input-underline input-lg" >
                          <option disabled selected value="">Choisissez une option</option>
                          <option value="startup">1-19</option>
                          <option value="petite">20-99</option>
                          <option value="moyenne">100-499</option>
                          <option value="grande">Plus de 500</option>
                        </select>
                        <span class="error  mt-0" v-if="v$.entreprise.taille.$error" >
                        {{ v$.entreprise.taille.$errors[0].$message }}
                      </span>
                    </div>
                     
                     <div  class="form-group">
                      <input type="text" v-model="state.entreprise.adresse"  class="form-control input-underline input-lg"   placeholder="Localisation">
                    </div>
                    <span class="error  mt-0" v-if="v$.entreprise.adresse.$error" >
                        {{ v$.entreprise.adresse.$errors[0].$message }}
                      </span>

                     <div >
                      <input type="password"  v-model="state.entreprise.password"  class="form-control input-underline input-lg"  placeholder="Mot de passe">
                    </div>
                    <span class="error  mt-0" v-if="v$.entreprise.password.$error" >
                        {{ v$.entreprise.password.$errors[0].$message }}
                    </span>

                   
                    <div  class="form-group">                  
                      <input type="password"  v-model="state.confirm_password"  class="form-control input-underline input-lg"  placeholder="Confirmer votre mot de passe">
                    </div>
                    <span class="error  mt-0" v-if="v$.confirm_password.$error" >
                      {{ v$.confirm_password.$errors[0].$message }}  
                    </span>
                    
                    <div class=" mt-1 mb-2">
                      <button class="btn  btn-info" @click="goToStep(1)">Precédent</button>
                    </div>
                  

                    <button type="submit"  class="btn btn-info btn-lg" style="width:50%">S'inscrire</button>
                   
        </div>
    </div>
    
    </form>
    </div>
    </div>
  </div>
  </template>
 

<script>
    import {account} from '@/services'
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, sameAs, minLength, helpers } from '@vuelidate/validators'
    import {reactive, computed} from 'vue';

export default {
    data() {
        return {
          currentState: 1,
          errors: []
        }
    },
    setup(){
      const state =  reactive({
        entreprise:{
            denomination: '',
            email: '',
            taille: '',
            adresse: '',
            password: '',
            ifu:'',
            rccm:'',
          },
          confirm_password: '',
      })

      const rules = computed(() => {
        return {
          entreprise: {
                denomination: {
                  required: helpers.withMessage("Ce champ est requis", required),
                },
                email: {
                  required: helpers.withMessage("Ce champ est requis", required),
                  email: helpers.withMessage("Email invalid ou déjà utiliser", email)
                },
                taille: { required: helpers.withMessage("Ce champ est requis", required) },
                adresse: { required: helpers.withMessage("Ce champ est requis", required) },
                password: {
                  required: helpers.withMessage("Ce champ est requis", required),
                  minLength: helpers.withMessage("Le minimum de caractere pour le mot de passe est 8", minLength(8))
                }
              },
              confirm_password: {
                required: helpers.withMessage("Ce champ est requis", required),
                sameAs: helpers.withMessage("Les mots de passe ne sont pas identiques", sameAs(state.confirm_password)),
              },
        }
      })
      const v$ = useVuelidate(rules, state)
      return {
        state,
        v$
      }
    },
    computed:{
      errorLength(){
        return this.errors.length
      }
    },
    methods:{
      validationForm: function(){
        this.v$.$validate()
        account.register(this.state.entreprise).then((response) => {
              const token = response.data.token;
              localStorage.setItem('token', token);
              this.$router.push('/connexion-entreprise')
            })
        this.currentState = 1

      },
      goToStep: function (step) {
        this.currentState = step;
      },
    },
  }

</script>

<style scoped>
.login-page{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    text-align: center;
    color: #fff;

    width: 100%;
    
  height: auto;
  background-image: url('../../../public/performance.jpg');

  background-size: cover;
  background-attachment: fixed;
}


.logo{
    border-radius: 50%;
    box-shadow: #222 3px 3px 3px 0px;
}

.user-avatar {
    width: 185px;
    height: 185px;
}

.login-page h1 {
    font-weight: 300;
}

.login-page .login-form .form-content {
    padding: 0;
}
.login-form{
    background-color:rgba(29, 72, 81,.7);
    border-radius: 20px;
    width: 35%;
    height: auto;
    margin: auto;
    margin-top: 5px;
    text-align: center;
    backdrop-filter: blur(10px);
}

.form-content{
  width: 90%;
  margin: auto;
 height: auto;
}

.login-page .login-form .form-content .input-underline {
    background: 0 0;
    border: none;
    box-shadow: none;
    border-bottom: 2px solid rgba(255,255,255,.4);
    color: #fff;
    border-radius: 0;
}
.login-page .login-form .form-content .input-underline:focus {
    border-bottom: 2px solid #fff;
}    

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 0;
}
.btn_inscrire{
  width: 100%;
}
.btn-info{
    border-radius: 50px;
    box-shadow: 0 0 0 2px rgba(255,255,255,.8)inset;
    color: rgba(255,255,255,.8);
    background: 0 0;
    border-color: transparent;
    font-weight: 400;
    width:35%;    
}

.btn-info:hover{
  background-color: #eb5d1e;
  border: white;
}
a{
    text-decoration: none;
    border-radius: 50%;
}
input[type='email']::-webkit-input-placeholder, input[type='password']::-webkit-input-placeholder, input[type='number']::-webkit-input-placeholder, input[type='text']::-webkit-input-placeholder{ 
    color:    #fff;
}
input[type='email']:-moz-placeholder, input[type='password']:-moz-placeholder, input[type='number']:-moz-placeholder, input[type='text']:-moz-placeholder { 
    color:    #fff;
}
input[type='email']::-moz-placeholder, input[type='password']::-moz-placeholder, input[type='number']::-moz-placeholder, input[type='text']::-moz-placeholder { 
    color:    #fff;
}
input[type='email']:-ms-input-placeholder, input[type='password']:-ms-input-placeholder,input[type='number']:-ms-input-placeholder,input[type='text']:-ms-input-placeholder { 
    color:    #fff;
}
</style>