<template>
              <RouterView></RouterView>
              <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header">
                          <h3 class="card-title">Mes Evaluations</h3>
                      </div>
                      <div class="card-body">
                      <ListePerformance></ListePerformance>
                </div>
                              
                                </div>
                  </div>            
              </div>
        
</template>

<script>
    
   // import MainContent from '@/components/DashboardComponent/MainContent.vue'
    import ListePerformance from '@/components/Performance/ListePerformance.vue'
    export default{
        data(){
            return{

            }
        },
        components:{
           // 'MainContent': MainContent,
            ListePerformance
        }
    }
</script>

<style scoped>
  .icon{
    height: 5vh;
    width:5vh;
  }
  th{
    text-align: center;
  }
</style>