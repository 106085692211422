
<template>
    <form @submit.prevent="updateProfile" class="row" method="post">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Modifier vos informations</h3>
                    </div>
                    <div class="card-body">
                        <!-- Nom && Prénom -->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputText">Nom</label>
                                    <input v-model="state.employe.nom"  type="text" class="form-control" id="exampleInputText"  placeholder="Nom">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Prenom  </label>
                                    <input v-model="state.employe.prenom" type="email" class="form-control" id="exampleInputEmail1" placeholder="Prenom">
                                </div>
                            </div>
                        </div>

                        <!-- Sexe, Naissance, Telephone  -->
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputIfu">Téléphone</label>
                                    <input class="form-control" id="exampleInputIfu" type="text"  v-model="state.employe.telephone"  placeholder="Téléphone">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputRccm">Naissance</label>
                                    <input class="form-control" type="date" id="exampleInputRccm"  v-model="state.employe.naissance"  placeholder="Naissance">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputSexe">Sexe</label>
                                    <select class="form-select mb-3" id="exampleInputSexe">
                                        <option selected disabled >Genre</option>
                                        <option value="Masculin">Masculin</option>
                                        <option value="Féminin">Féminin</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <!-- Adresss && Email -->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail">Email </label>
                                    <input v-model="state.employe.email" type="email" class="form-control" id="exampleInputEmail"  placeholder="Email">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputAdresse">Adresse  </label>
                                    <input v-model="state.employe.adresse" type="text" class="form-control" id="exampleInputAdresse"  placeholder="Adresse">
                                </div>
                            </div>
                        </div>

                        <!-- Password et confirm_Password -->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Mot de passe  </label>
                                    <input v-model="state.employe.password" class="form-control" type="password" name="" id="exampleInputPassword1">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputPassword2">Confirmer Mot de passe  </label>
                                    <input v-model="state.confirm_password" class="form-control" type="password" name="" id="exampleInputPassword2">
                                </div>
                                <span class="error  mt-0" v-if="v$.confirm_password.$error" >
                                    {{ v$.confirm_password.$errors[0].$message }}  
                                </span>
                            </div>
                        </div>

                        <div style="text-align:center">
                            <button type="submit" class=" btn button">Modifier</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

import {account} from '@/services'
import { useVuelidate } from '@vuelidate/core'
import { sameAs, helpers } from '@vuelidate/validators'
import {reactive, computed} from 'vue';
import store from '@/store'

export default{
data() {
return {
    user: store.getters.getUser
}
},
components:{
},
setup(){
const state =  reactive({
    employe:{
        nom: store.getters.getUser.nom,
        prenom: store.getters.getUser.prenom,
        sexe: store.getters.getUser.sexe,
        naissance: store.getters.getUser.naissance,
        telephone: store.getters.getUser.telephone,
        adresse: store.getters.getUser.adresse,
        email: store.getters.getUser.email,
        password: ''
    },
    confirm_password: '',
    userId: store.getters.getUser.id,
})

const rules = computed(() => {
    return {
    employe: {
        },
        confirm_password: {
            sameAs: helpers.withMessage("Les mots de passe ne sont pas identiques", sameAs(state.employe.password )),
        },
    }
})
const v$ = useVuelidate(rules, state)
return {
    state,
    v$
}
},
methods: {
updateProfile: function(){
this.v$.$validate()
account.updateProfileEmploye(this.state.employe, this.state.userId ).then((response) => {
    const user = response.data.user;
    store.commit('setUser', user)
    this.$router.go()
    })
},
},
}
</script>

<style scoped>
.required{
color: red;
}
.button{
text-align: center;
color: white;
background-color: #eb5d1e;
}
</style>