<template>
            <RouterView></RouterView>
            <div class="mb-2 mr-2 btn-group">
              <button class="btn" style="backgroundColor:#eb5d1e;color:#fff" @click="downloadAsPDF"><font-awesome-icon :icon="['fas', 'download']" /> Imprimer</button>
              </div>
             
              <div class="row">
                  <div class="col-12">
                      <div class="card">
                          <div class="card-header">
                              <h3 class="card-title">Indicateurs</h3>
                          </div>
                  <!-- /.card-header -->
                          <div class="card-body">
                              <IndicateurRatios :id="id"></IndicateurRatios>
                          </div>    
                  <!-- /.card-body -->

                      </div>
                  </div>
              </div>
            
</template>

<script>
 //import MainContent from '@/components/DashboardComponent/MainContent.vue'
 import moment from 'moment';
 import IndicateurRatios from '@/components/Performance/IndicateurRatio.vue';
 import html2pdf from 'html2pdf.js'
import store from '@/store';
    export default {
        props: ['id'],
  data () {
    return {
        user:store.getters.getUser,
        chemin:'../../../../logokhc.png',

    }
  },
  components:{
   // 'MainContent': MainContent,
    IndicateurRatios
  },
  methods: {
  downloadAsPDF() {
    const image=new Image();
    image.src=this.chemin;
   const tempDiv=document.createElement('div');
    const kperform=document.createElement('div');
   kperform.style.display='inline-block';
   kperform.style.width = '200px';
    kperform.innerHTML="<img src='../../../../logokhc.png' width=70 height:70><p>K-PERFORM<br>Ilot N°742 <br>Quartier GBEGAMEY Nord<br>Immeuble Bhélix</p>";
    tempDiv.appendChild(kperform);
    const entreprise=document.createElement('div');
    entreprise.style.width='510px';
    entreprise.innerHTML="<p>"+this.user.denomination+"<br>"+this.user.email+"<br>"+this.user.adresse+"</p>";
    entreprise.style.display='inline-block';
    entreprise.style.verticalAlign='baseline'
    entreprise.style.marginRight=0;
    
   entreprise.style.textAlign='right';
    tempDiv.appendChild(entreprise);
    const rapport= document.querySelector('#rapport');
    tempDiv.appendChild(rapport);
    //const element = document.querySelector('#telecharger'); // ID de la table à convertir en PDF
      const options = {
        filename: 'Rapport Final',
        html2canvas: { scale:2, backgroundColor: '#FFF' }, // Augmenter l'échelle pour une meilleure résolution
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', compress: true }, // Utiliser la compression du PDF
        image: { type: 'jpeg', quality: 0.98 }, // Utiliser le format JPEG avec une meilleure qualité
        margin: [10, 10, 20, 10], // Ajouter des marges pour éviter les coupures
        pagebreak: { mode: 'avoid-all' ,before:"#tableau"}, // Éviter les sauts de page avant la table
        };
      console.log(tempDiv);
      html2pdf().set(options).from(tempDiv).toPdf().get('pdf').then(function(pdf){
        var totalPages=pdf.internal.getNumberOfPages();
        for (let i =1 ; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(100);
            var cur_dttm = moment().format("MMMM, DD YYYY hh:mm:ss a");
            pdf.text('Rapport Final ' + cur_dttm, (pdf.internal.pageSize.getWidth()/3.25), (pdf.internal.pageSize.getHeight()-10));     
            pdf.text('Page ' + i + ' sur ' + totalPages, (pdf.internal.pageSize.getWidth()-200), (pdf.internal.pageSize.getHeight()-10));

        }
      }).save()

        },
    },

        
    }

</script>

<style scoped>
.icon{
    width:5vh;
    height:5vh;
}
th{
    text-align: center;
}

</style>