<template>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Ajouter un Employé</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="creerEmploye">
                    <div class="modal-body">
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="position-relative form-group"><label for="nom" class="">Nom</label><input name="nom" v-model="model.employe.nom" id="nom" placeholder="Entrer votre nom" type="text" class="form-control" required></div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="position-relative form-group"><label for="prenom" class="">Prenom</label><input name="prenom" v-model="model.employe.prenom" id="prenom" placeholder="Entrer vos prenom" type="text" class="form-control" required></div>
                                </div>
                            </div>
                    
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="position-relative form-group"><label for="naissance" class="">Date de Naissance</label><input id="naissance" v-model="model.employe.naissance" type="date" class="form-control" required></div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="position-relative form-group"><label for="adresse" class="">Adresse</label><input name="adresse" v-model="model.employe.adresse" id="adresse" placeholder="Ex: Calavi godomey" type="text" class="form-control" required></div>
                                </div>
                            </div>
                    
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="position-relative form-group"><label for="sexe" class="">Sexe</label>
                                        <select type="select" id="sexe" name="sexe" v-model="model.employe.sexe" class="custom-select form-select" required>
                                                <option disabled selected value="">Entrer votre genre</option>
                                                <option value="masculin">Masculin</option>
                                                <option value="feminin">Feminin</option>
                                                <option value="autres">Autres</option>
                                               
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="position-relative form-group"><label for="email" class="">Adresse electronique</label><input name="email" v-model="model.employe.email" id="email" placeholder="Entrer votre adresse electronique" type="email" class="form-control" required></div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="position-relative form-group"><label for="telephone" class="">Telephone</label><input name="telephone" v-model="model.employe.telephone" id="telephone" placeholder="Entrer votre numero de telephone" type="tel" class="form-control" required></div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="position-relative form-group"><label for="departement" class="">Departement</label>
                                        <select type="select" id="departement" name="departement" class="custom-select form-select" >
                                                <option disabled selected value="">Département</option>
                                                <option @click="poste(item.id)" v-for="item in departements" :key="item.id">{{item.libelle}}</option>                                               
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="position-relative form-group"><label for="poste" class="">Poste</label>
                                        <select type="select" id="poste" name="poste" v-model="model.employe.poste_id" class="custom-select form-select" >
                                                <option disabled selected value="">Poste occupé</option>
                                                <option v-for="item in postes" :key="item.id" :value="item.id">{{item.libelle}}</option>
                                        </select>
                                    </div>                                                                                                                                           
                                </div>
                            </div>
                            
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" ref="close" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary" >Enregistrer</button>
                    </div>
                </form>           
            </div>
        </div>
    </div>
    <div class="main-card mb-3 card">
        <div class="card-body"><h5 class="card-title">Ajouter un Employé</h5>
            <form @submit.prevent="creerEmploye" class="">
                <div class="mt-3 form-row">
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="nom" class="">Nom</label><input name="nom" v-model="model.employe.nom" id="nom" placeholder="Entrer votre nom" type="text" class="form-control" required></div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="prenom" class="">Prenom</label><input name="prenom" v-model="model.employe.prenom" id="prenom" placeholder="Entrer vos prenom" type="text" class="form-control" required></div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="email" class="">Adresse electronique</label><input name="email" v-model="model.employe.email" id="email" placeholder="Entrer votre adresse electronique" type="email" class="form-control" required></div>
                    </div>
                </div>
                <div class="mt-3 form-row">
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="naissance" class="">Date de Naissance</label><input id="naissance" v-model="model.employe.naissance" type="date" class="form-control" required></div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="adresse" class="">Adresse</label><input name="adresse" v-model="model.employe.adresse" id="adresse" placeholder="Ex: Calavi godomey" type="text" class="form-control" required></div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="sexe" class="">Sexe</label>
                            <select type="select" id="sexe" name="sexe" v-model="model.employe.sexe" class="custom-select form-select" required>
                                    <option disabled selected value="">Entrer votre genre</option>
                                    <option value="masculin">Masculin</option>
                                    <option value="feminin">Feminin</option>
                                    <option value="autres">Autres</option>
                            </select>
                        </div>                    
                    </div>
                </div>
                <div class="mt-3 form-row">
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="telephone" class="">Telephone</label><input name="telephone" v-model="model.employe.telephone" id="telephone" placeholder="Entrer votre numero de telephone" type="tel" class="form-control" required></div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="departement" class="">Departement</label>
                            <select type="select" id="departement" name="departement" class="custom-select form-select" >
                                    <option disabled selected value="">Département</option>
                                    <option @click="poste(item.id)" v-for="item in departements" :key="item.id">{{item.libelle}}</option>                                               
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group"><label for="poste" class="">Poste</label>
                            <select type="select" id="poste" name="poste" v-model="model.employe.poste_id" class="custom-select form-select" >
                                    <option disabled selected value="">Poste occupé</option>
                                    <option v-for="item in postes" :key="item.id" :value="item.id">{{item.libelle}}</option>
                            </select>
                        </div> 
                    </div>
                </div>
                <div class="mt-2 " style="text-align:center">
                    <button type="submit" class="btn btn-success" >Enregistrer</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import {rh} from '@/services';
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            id:store.getters.getUser.id,
            departements:[],
            postes:[],
            model:{
                employe:{
                    nom:'',
                    prenom:'',
                    sexe:'',
                    email:'',
                    naissance:'',
                    telephone:'',
                    adresse:'',
                    poste_id:'',
                    user_id: store.getters.getUser.id
                }
            }
        }
    },
    
    components:{
    },
    methods: {
        afficherMessage() {
            Swal.fire({
              title: 'Traitement en cours',
              text: 'Veuillez patienter...',
              allowOutsideClick: false,
            });
            Swal.showLoading();
          },
          masquerMessage() {
            Swal.close();
          },
        async creerEmploye(){
            this.afficherMessage();
            await rh.addEmploye(this.model.employe).then((response)=>{
                console.log(response.data)
                this.$refs.close.click();
            })
            .catch((error) => {
                console.log(error);
                this.masquerMessage();
            })
            this.$router.push('/employe');
        },
        poste(id){
            rh.posteSpecifique(id).then((response)=>{
                this.postes=response.data.postes
                console.log(this.postes);
            })
        }
    },
    mounted() {
        rh.allDepartement(this.id).then((response)=>{
        this.departements=response.data.departements 
        console.log(this.departements);
    })
    },
}
</script>

<style>
.button{
    text-align: center;
    color: white;
    background-color: #eb5d1e;
}
</style>