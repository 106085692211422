
<template>
    <div>
            <!--<div class="mb-3 card">
              <h4 class="mt-4">Ce guide est conçue pour vous accompagner de manière transparente tout au long du processus d'évaluation qui se résume en trois étapes simple.</h4>
              <div class="card-header card-header-tab-animation">
                  <ul class="nav nav-justified">
                      <li class="nav-item"><a data-toggle="tab" href="#tab-eg115-0" class="active nav-link">1</a></li>
                      <li class="nav-item"><a data-toggle="tab" href="#tab-eg115-1" class="nav-link">2</a></li>
                      <li class="nav-item"><a data-toggle="tab" href="#tab-eg115-2" class="nav-link">3</a></li>
                  </ul>
              </div>
              <div class="card-body">
                  <div class="tab-content">
                      <div class="tab-pane active" id="tab-eg115-0" role="tabpanel">
                         
                              <h2 >Télécharger le modèle</h2>
                              <div class="list-group-item">
                                  <p class="description">
                                    Cher utilisateur, nous vous invitons à compléter certaines informations importantes de votre bilan fonctionnel et de votre compte de résultat dans une feuille Excel. Veuillez fournir les détails pertinents tels que les actifs, les passifs, les revenus et les dépenses afin de nous aider à évaluer votre situation financière de manière précise. Ces renseignements seront traités de manière confidentielle et serviront à optimiser nos services en vous fournissant des analyses personnalisées. Merci d'avance pour votre collaboration précieuse !
                                  </p>
                              </div>
                              <div class="panel panel-default bootcards-file">
                                <div class="panel-heading">
                                </div>
                                <div class="list-group">
                                  <div class="list-group-item">
                                    <a href="#">
                                      <img src="@/assets/excel.svg" alt="" width="100">
                                    </a>
                                    <h4 class="list-group-item-heading">
                                                <span href="#">
                                                  Modele de Fichier
                                                </span>
                                                </h4>
                                    <p class="list-group-item-text"><strong>EXCEL</strong></p>
                                    <p class="list-group-item-text"><strong>1.5MB</strong></p>
                                  </div>
                                </div>
                                <div class="panel-footer mt-2">

                                  <button class="btn btn-outline-focus description" @click="downloadFile"> 
                                    <font-awesome-icon :icon="['fas', 'download']" />
                                    Télécharger
                                  </button>
                                </div>
                              </div>
                             
                    </div>
                      <div class="tab-pane" id="tab-eg115-1" role="tabpanel">
                        <h2 class="mb-3">Importer le modèle</h2>
                        <p class="description">Veuillez importer le modèle que vous avez précédemment téléchargé après l'avoir rempli. Assurez-vous de fournir toutes les informations requises pour bénéficier pleinement de notre plateforme. L'importation du modèle rempli nous permettra de traiter vos données de manière efficace et de vous offrir une expérience optimale.</p>

                        <div class="container">
                          <div class="row">
                            <label for="images" class="drop-container">
                              <span class="drop-title">Déposer votre fichier ici </span>
                              ou
                              <input @change="handleFile" type="file" ref="fileInput" id="excel" accept=".xlsx, .xls" required>
                            </label>
                          </div>
                        </div>
                      
                    </div>
                      <div class="tab-pane" id="tab-eg115-2" role="tabpanel">
                        <h2 class="mb-3">Générer le rapport</h2>
                        <p class="description">
                          Vous etes arrivés à la fin du processus. K-PERFORM analysera les données financièreset calculera les indicateurs clés.Ensuite,
                           un rapport d'évaluation claire et détaillé sera automatiquement genéré, vous presentant les resultats et les conclusions concernant
                           la performance financière de votre entreprise.
                        </p>
                        <div class="icon">
                          <i class="fa fa-fw" aria-hidden="true" title="Copy to use cogs"></i>
                      </div>
                      <div class="panel-footer mt-2">
                        <form  @submit.prevent="submitForm" method="post">
                          <button type="submit" class="btn btn-outline-focus description">
                            Soumettre
                          </button>
                        </form>

                      </div>
                      </div>
                        
                  </div>
              </div>
          </div>-->
          <section  class="container pt-3 mb-3">
            <h4 class="mt-4">Ce guide est conçue pour vous accompagner de manière transparente tout au long du processus d'évaluation qui se résume en trois étapes simple.</h4>
      
            <div class="row pt-5 mt-30">
                <div class="col-lg-4 col-sm-6 mb-30 pb-5">
                    <a @click="selected" class="card" href="#">
                        <div class="box-shadow bg-white rounded-circle mx-auto text-center" style="width: 90px; height: 90px; margin-top: -45px;"><i class="fa fa-file-download fa-3x head-icon"></i></div>
                        <div class="card-body text-center">
                            <h3 class="card-title pt-1">Modele de Fichier</h3>
                            <p class="card-text text-sm">Cher utilisateur, nous vous invitons à compléter certaines informations importantes de votre bilan fonctionnel et de votre compte de résultat dans une feuille Excel. Veuillez fournir les détails pertinents tels que les actifs, les passifs, les revenus et les dépenses afin de nous aider à évaluer votre situation financière de manière précise. Ces renseignements seront traités de manière confidentielle et serviront à optimiser nos services en vous fournissant des analyses personnalisées. Merci d'avance pour votre collaboration précieuse !
                            </p>
                            <button class="mb-1 btn btn-outline-focus description" @click="downloadFile"> 
                              <font-awesome-icon :icon="['fas', 'download']" />
                              Télécharger
                            </button>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-sm-6 mb-30 pb-5">
                    <a @click="selected" class="card" href="#">
                        <div class="box-shadow bg-white rounded-circle mx-auto text-center" style="width: 90px; height: 90px; margin-top: -45px;"><i class="fa fa-file-import fa-3x head-icon"></i></div>
                        <div class="card-body text-center">
                            <h3 class="card-title pt-1">Importer le modele de FIchier</h3>
                            <p class="card-text text-sm">Veuillez importer le modèle que vous avez précédemment téléchargé après l'avoir rempli. Assurez-vous de fournir toutes les informations requises pour bénéficier pleinement de notre plateforme. L'importation du modèle rempli nous permettra de traiter vos données de manière efficace et de vous offrir une expérience optimale.</p>
                            <div class="mb-2">
                              <label for="images" class="drop-container">
                                <span class="drop-title">Déposer votre fichier ici </span>
                              
                                <input @change="handleFile" type="file" ref="fileInput" id="excel" accept=".xlsx, .xls" required>
                              </label>
                          
                            </div>
                               
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-sm-6 mb-30 pb-5">
                    <a @click="selected" class="card" href="#">
                        <div class="box-shadow bg-white rounded-circle mx-auto text-center" style="width: 90px; height: 90px; margin-top: -45px;"><i class="fa fa-stethoscope fa-3x head-icon"></i></div>
                        <div class="card-body text-center">
                            <h3 class="card-title pt-1">Evaluer</h3>
                            <p class="card-text text-sm">Vous etes arrivés à la fin du processus. K-PERFORM analysera les données financièreset calculera les indicateurs clés.Ensuite,
                              un rapport d'évaluation claire et détaillé sera automatiquement genéré, vous presentant les resultats et les conclusions concernant
                              la performance financière de votre entreprise..</p>
                              <div class="mt-5">
                                <form  @submit.prevent="submitForm" method="post">
                                  <button type="submit" class="mt-5 btn btn-outline-focus description">
                                    Evaluer
                                  </button>
                                </form>
                              </div>
                              
                        </div>
                    </a>

                </div>
            </div>
        </section>
    </div>

   
</template>

<script>

//import MainContent from '@/components/DashboardComponent/MainContent.vue'
import {account} from '@/services'
import { saveAs } from 'file-saver';
import xlsx from 'xlsx/dist/xlsx.full.min.js'
import store from '@/store'
import axios from 'axios'
import Swal from 'sweetalert2';
import { performance } from '@/services';
    export default {
        components: {
          //  'MainContent': MainContent
        },
        data(){
            return{
                currentState: 1,
                selectedFile:'',
                perform:{},
                b_fonctionnels: {},
                c_resultats:{},
                t_tresorerie:{},
                infos_comp:{},
                ratios: {},
                performances:[]
            }
        },
        methods:{
          afficherMessage() {
            
  Swal.fire({
    title: 'Traitement en cours',
    text: 'Veuillez patienter...',
    allowOutsideClick: false,
  });
  Swal.showLoading();
},
masquerMessage() {
  Swal.close();
},
          handleFile() {
            this.selectedFile = this.$refs.fileInput.files[0];
          },
         async createPerform(financiers, resultats,tresorerie,infos, userId){
          try{
           this.afficherMessage();
            const response = await axios.post(process.env.VUE_APP_LARAVELAPI+'/createPerform',{
        userId,
        financiers: {
            actifs_immobilise: financiers['Actif immobilisée'],   
            capitaux_propres:  financiers['Capitaux propres'],
            actifs_circulant:financiers['Actifs circulant'],
            dettes_financieres:financiers['Dettes financières'],
            tresorerie_actif:financiers['Trésorerie actif'],
            passif_circulants:financiers['Passif circulants'],
            tresorerie_passif:financiers['Trésorerie passif'],
            total_passif:financiers['Total passif'],
            total_actif:financiers['Total actif'],
            creance:financiers['Créances'],
            stock:financiers['Stocks'],
            fournisseur:financiers['Fournisseur']
        },
        resultats:{
            valeur_ajoute: resultats['Valeur Ajouté'],
            achat_horsTaxe: resultats['Achat de stock HT'],
            charge_salariales: resultats['Charge Salariale'],
            variation_stocks:  resultats['Variation de Stock'],
            charge_financiere: resultats['Charge Financière'],
            chiffre_affaire: resultats['Chiffre d’affaire'],
            ebe: resultats['Excédent Brut d’Exploitation'],
            rex: resultats['Résultat d’exploitation net'],
            amortissement_et_provisions_exploitation:resultats['Amortissement et provisions d’exploitation'],
            rn: resultats['Résultat net'],
            resultat_exceptionnel: resultats['Résultat Exceptionnel'],
            amortissement_exceptionnel:resultats['Amortissement Exceptionnel'],
            impot_societe:resultats['Impot'],
            rf: resultats['Résultat financier'],
            amortissement_financier:resultats['Amortissement financier'],
            ebit:resultats['EBIT'],
            cmpd:infos['CMPD'],
            effectif:infos['Effectifs'],
            taux_impot:infos['Taux Impot'],
            taux_tva:infos["Taux TVA"],
            exercice:infos["Année de l’exercice"]
        },

        tresorerie:{
          t_exploitation:tresorerie["Flux de trésorerie d'exploitation"],
          t_investissement:tresorerie["Flux de trésorerie d'investissement"],
          t_financement:tresorerie["Flux de trésorerie de financement"],
          variation_creance:tresorerie["Variations compte créance et assimilés"],
          variation_fournisseur:tresorerie["Variations compte fournisseur et assimilés"]
        }
    });
    this.ratios = response.data.ratios
    this.masquerMessage();
                this.$router.push({ name: 'detail-performance', params: { id: response.data.performanceId } });
          } catch (error) {
            this.masquerMessage();
            performance.getPerformances(store.getters.getUser.id).then((response)=>{
              this.performances=response.data.performances
              const $taille=this.performances.length-1;
          const $lastPerformanceId=this.performances[$taille].id; 
          performance.deletePerform($lastPerformanceId)
            })
         
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "    Verifier votre connexion internet",
                })
        console.error(error);
      }
          
},
          submitForm(){
            if (this.selectedFile=="") {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Veuillez Importer le modele de Fichier rempli ",
                })
            } else {
              const reader = new FileReader();
            reader.readAsBinaryString(this.selectedFile)
            
            reader.onload = (e) => {
              const data = e.target.result;
              const workbook = xlsx.read(data, {type: 'binary'}); // tout le fichier excel
              workbook.SheetNames.forEach(sheetName => {
              const worksheet = workbook.Sheets[sheetName];
              const sheetData = xlsx.utils.sheet_to_json(worksheet, { header:['A3','B3']});
              
              //recupération de chaque feuille sous forme d'objet json
              this.perform[sheetName] = {}
              sheetData.forEach(row => {
                const key = row['A3']
                const value = row['B3']
                this.perform[sheetName][key] = value
              });
            });
            console.log(this.perform['TFT']);
            this.b_fonctionnels = this.perform.b_fonctionnels;
            this.c_resultats = this.perform.c_resultats;
            this.t_tresorerie=this.perform['TFT'];
            this.infos_comp=this.perform['infos_complementaire'];
            this.createPerform(this.b_fonctionnels, this.c_resultats,this.t_tresorerie,this.infos_comp ,store.getters.getUser.id);
            };
            }
            
          },
          downloadFile(){
            account.downloadModel().then((response) =>{
              console.log(response)
              saveAs(response.data,"performance.xlsx");
            }).catch((error) => {
              console.log(error);
            })
            },  
            goToStep: function (step) {
              this.currentState = step;
            },
        }
    }
</script>

<style scoped>
.icon{
  font-size:80px;
  text-align: center;
}
.description{
  text-align: justify;
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-size: 18px;
}
h2,h4{
  text-align:center;
  font-family:Georgia, 'Times New Roman', Times, serif;
}
body {
  margin: 20px 60px;
}

.panel-footer{
  text-align: center;
}

.btn-info{
  border-radius: 50px;
  box-shadow: 0 0 0 2px rgba(255,255,255,.8)inset;
  color: rgba(255,255,255,.8);
  background: #f66303;
  width: 50%;
  margin: auto;

  border-color: transparent;
  font-weight: 400;
}
.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

input[type=file] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}

input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #0d45a5;
}
.big_content{
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
}


.list-group{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}
.btn-group-justified{
  
  margin-top: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

body{
  margin-top:20px;
  background:#eee;
}
.card:hover{
  border: 2px solid #1D4851;
}

a.card {
  text-decoration: none;
}

.card {
  position: relative;
  border: 0;
  border-radius: 0;
  background-color: #fff;
  min-height: 510px;
  -webkit-box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09);
  box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09);
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: .25rem;
}

.box-shadow {
  -webkit-box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09) !important;
  box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09) !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}
.mr-auto, .mx-auto {
  margin-right: auto !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.bg-white {
  background-color: #fff !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}
.d-block {
  display: block !important;
}
img, figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.card-text {
  padding-top: 12px;
  color: #000;
}

.text-sm {
  font-size: 14px !important;
  text-align: justify;
}
p, .p {
  margin: 0 0 16px;
}

.card-title {
  margin: 0;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 900;
  color: #1D4851;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.head-icon{
  margin-top:18px;
  color:#c9552b
}

</style>