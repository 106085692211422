<template>
        <div class="contenu row">
            <div class="texte"><h3>Efficacité, Efficience, Mesurer la performance n'a jamais été aussi facile...</h3></div>
            <div class="row">
                <div class="col-6 connecter">
                    <RouterLink  class="btn btn-info btn-lg" to="/connexion"> Espace Employé</RouterLink>
                </div>
                <div class="col-6 connecter"> 
                    <RouterLink class="btn btn-info btn-lg" to="/connexion-entreprise">Espace Entreprise</RouterLink>    
                </div>
            </div>               
        </div>
</template>
    <style scoped>  
   .texte{
    height:100px;
    width:auto;
    margin:auto;
    color:white;
   }
   
    .connecter{
        display:flex;
        align-items:center;
        justify-content:center;
    }
   
.contenu{
           position:fixed;
           top:0;
           bottom:0;
           left:0;
           right:0;
            background:url('../../public/premierepage.jpg');
            background-color: rgba(0,0,0,0.3);
            background-position:center;
            background-repeat: no-repeat;
            background-size:cover;
    }
    .btn-info{
    border-radius: 50px;
    box-shadow: 0 0 0 2px rgba(255,255,255,.8)inset;
    color: rgba(255,255,255,.8);
    background: 0 0;
    margin-top: 15px;
    border-color: transparent;
    font-weight: 400;
}

.btn-info:hover{
    background-color: #eb5d1e;
    border: white;
}
a{
    text-decoration: none;
    border-radius: 50%;
}
    .personne{
       display: inline-block;
        
        width:auto;
        margin: auto;
            font-weight: bold;
            color: #050505;
            font-size: 13px;
            letter-spacing: 1.5;
            text-transform: uppercase;
            bottom: 10%;
           
    }

    
    
    </style>