<template>
    <div class="modal fade" id="CreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Ajouter un departement</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="creerDepartement">
                    <div class="modal-body">
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="position-relative form-group"><label for="libelle" class="form-label">Libellé</label><input name="libelle"  id="libelle" v-model="model.departement.libelle" placeholder="Ex: Marketing..." type="text" class="form-control" required></div>
                                <div v-if="v$.model.departement.libelle.$error"> Libelle manquant</div>
                            </div>
                            
                        </div>                    
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" ref="close" data-dismiss="modal">Fermer</button>
                        <button type="submit" class="btn btn-primary" >Enregistrer</button>
                    </div>
                </form>           
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {required,email} from '@vuelidate/validators';
import { rh } from '@/services';
import store from '@/store';
export default {
    name:'CreateDepartement',
    setup(){
        return {v$ : useVuelidate()}
    },
data() {
    return {
        userId:store.getters.getUser.id,
        model:{
            departement:{
                libelle:''
            }
        },
    }
},
validations(){
    return{
        model:{
            departement:{
                libelle:{required,email}
            }
        }
    }
},

methods: {
    creerDepartement(){
        console.log(this.model.departement)
        rh.newDepartement(this.model.departement,this.userId).then(
            this.$refs.close.click(),
            this.$emit('updateDepartement')
        )
        this.model.departement.libelle = ""
    },
    
},
}
</script>

<style>

</style>