<template>
    <Teleport to="body">
        <div class="modal fade" id="modal-default">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Nouvelle rubrique</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <label>Ajouter une nouvelle rubrique</label>
                        <input v-model.trim="modal.inputValue" type="text" class="form-control" placeholder="Une rubrique" >
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" >Fermer</button>
                        <button @click="addRubrique" type="button" class="btn btn-success">Ajouter</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="modal-question">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Nouvelle Question</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <label>Sélectionner le type de la question</label>
                        <select v-model="selectedType" class="form-control" @change="handleTypeChange">
                            <option disabled value="">Choisissez une option</option>
                            <option v-for="(item) in typeQuestion" :key="item.id" :value='item.id'> {{ item.libelle }} </option>
                        </select>
    
                        <label v-if="selectedType == 2  || selectedType == 1 || selectedType == 3">Ajouter une nouvelle question</label>
                        <input v-if="selectedType == 2  || selectedType == 1 || selectedType == 3" 
                        v-model.trim="modal.inputQuestion" type="text" class="form-control" 
                        :placeholder="selectedType == 2  ? 'Libellé de la question' : selectedType == 1 ? 'Libellé de la question' : 'Libellé de la question notée'"/>
    
                        <div v-if="selectedType == 2 ">
                            <label>Ajouter une réponse possible</label>
                            <input v-model.trim="modal.inputAnswer" type="text" class="form-control" placeholder="Réponse possible">
                            <button @click="addAnotherAnswer">Ajouter une autre réponse</button>
                        </div>
    
                        <div v-if="selectedType == 3">
                            <label>Maximum de points</label>
                            <input v-model.number="modal.maxPoints" type="number" class="form-control" placeholder="Maximum de points">
                        </div>
    
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" >Fermer</button>
                        <button @click="addQuestion" type="button" class="btn btn-success">Ajouter</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="modal-apercu">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Aperçu du questionnaire</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="body">
                            <div class="card contenu-questionnaire  ">
                                <h3>Questionnaire </h3>
                                <div v-for="item in movedList" :key="item.id" class="card">
                                    <div class="row">
                                        <div class="col text-left"> <h4>{{ item.rubrique }}</h4></div>
                                    </div>
                                    <span class="ctn-qts" v-for="(question, index) in item.questions" :key="question.id" >
                                        <div class="qts1">
                                            <p> <strong> {{ index + 1 }}-  </strong> {{ question.libelle }} </p>
                                            <div v-if="question.type == 1 ">
                                                <textarea class="form-control mb-2" cols="10" rows="3"></textarea>
                                            </div>
                                            <div class="form-check" v-if="question.type == 2 ">
                                                <div v-for="reponse in question.reponse" :key="reponse.id">
                                                    <input class="form-check-input" name="reponse" type="radio">
                                                    <label class="form-check-label" > {{ reponse }} </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="question.type == 3 " class="qts2">
                                            <input class="form-control" type="number" />
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" >Fermer</button>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <i class="fa fa-question" aria-hidden="true"></i>
                </div>
                <div>
                    Nouveau questionnaire
                </div>
            </div> 
        </div>
    </div>

    <div class="position-relative form-group">
        <label for="exampleEmail" class="">Référence du questionnaire</label>
        <input required v-model="reference" id="exampleEmail" placeholder="Ex: 'Questionnaire d'évaluation des développeurs'" type="text" class="form-control">
    </div>
    <div class="row">
        <div class="col-md-2 overflow-auto">
            <h4>Liste des rubriques</h4>
                <draggable group="my-group" :list="rubriques" class="list-group mt-3 draggable-list" tag="ul">
                    <template v-slot:item="{ element }">
                        <li class="list-group-item">{{ element.rubrique }}</li>
                    </template>
                </draggable>
            <button data-toggle="modal" data-target="#modal-default" class="btn btn-primary mt-3">Ajouter une rubrique</button>
        </div>
        <div class="col-md-10 droppable-column">
            <div class="row">
                <h4>Ajouter les rubriques de votre questionnaire ici <i class="fa fa-arrow-down    "></i></h4>
                <draggable group="my-group" :list="movedList" class="list-group pt-1 mt-1 draggable-list2 overflow-auto" tag="ul">
                    <template v-slot:item="{ element }">
                        <div class="row">
                            <div class="row mb-2">
                                <div class="col-md-10">
                                    <li class="list-group-item">{{ element.rubrique }}</li>
                                </div>
                                <div class="col-md-1">
                                    <span class="btn btn-danger"> <i @click="removeRubrique(element.rubrique)" class="fas fa-trash"></i> </span>
                                </div>
                                <div class="col-md-1">
                                    <span class="btn btn-success"> <i data-toggle="modal" @click="rubrique(element.rubrique)" data-target="#modal-question" class="fas fa-plus"></i> </span>
                                </div>
                            </div>
                            <div class="container">
                                <ol>
                                    <li class="d-flex justify-content-between" v-for="question in element.questions" :key="question.id">
                                        {{ question.libelle }}
                                        
                                        <span class=""  @click="removeQuestion(element, question)" v-if="question" >  <i class="question fa fa-window-close" aria-hidden="true"></i> </span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-6">
            <button type="submit" @click="soumettre" class="btn btn-success">Finaliser</button>
        </div>
        <div class="col-md-6">
            <button data-toggle="modal" data-target="#modal-apercu" class="btn btn-success">Aperçu du questionnaire</button>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import {rh} from '@/services'
    import { useToast } from "vue-toastification"; // POSITION
    import store from '@/store';
    import router from '@/router'
    import Swal from 'sweetalert2';

    export default {
    data() {
        return {
            toast: useToast(),
            reference: '',
            typeQuestion:[],
            selectedType: '',
            userId: store.getters.getUser.id,
            modal: {
                inputQuestion: '',
                inputAnswer: '', 
                maxPoints: null, 
            },
            answers: [], 
            movedList: [],
            selectedRubrique: '',
            rubriques: [
                {
                    rubrique: 'Compétence',
                    questions: []
                },
                {
                    rubrique: 'services rendu',
                    questions: []
                },
                {
                    rubrique: 'Dynamique au travail',
                    questions: []
                },
                {
                    rubrique: 'Aptitudes Comportementaux',
                    questions:[]
                },
                {
                    rubrique: 'Respect',
                    questions: []
                },
            ]
        }
    },

    components:{
        draggable: draggable,
    },
    mounted() {
        rh.allTypeQuestion().then((response) => {
            this.typeQuestion = response.data.typeQuestions
        })
    },
    methods:{
        afficherMessage() {
            Swal.fire({
              title: 'Traitement en cours',
              text: 'Veuillez patienter...',
              allowOutsideClick: false,
            });
            Swal.showLoading();
          },
          masquerMessage() {
            Swal.close();
          },
        handleTypeChange() {
            this.modal.inputQuestion = '';
            this.modal.inputAnswer = '';
            this.modal.maxPoints = null;
            this.answers = [];
            console.log(this.selectedType);
        },
        addAnotherAnswer() {
            if (this.modal.inputAnswer) {
                this.answers.push(this.modal.inputAnswer);
                this.modal.inputAnswer = '';
            }
            this.toast.success("Vous venez d'ajouter une nouvelle réponse")
        },
        async soumettre(){
            let movedList = JSON.stringify(this.movedList)
            this.afficherMessage();
            await rh.newQuestionnaire(movedList, this.reference, this.userId ).then((response) =>{
                console.log(response.status)
                if(response.status == 200)
                {
                    this.masquerMessage();
                    router.push('/list-questionnaire')
                }
            }).catch((error) => {
                console.log(error);
                this.masquerMessage();
            })
        },
        addRubrique(){
            if (this.modal.inputValue != '') {
                const rubriqueExiste = this.rubriques.some(obj => obj.rubrique === this.modal.inputValue);
                if (!rubriqueExiste) {
                    const obj = {rubrique: this.modal.inputValue, questions: []}
                    this.rubriques.push(obj)
                    this.modal.inputValue = '';
                }
            }
            this.toast.success("Vous venez d'ajouter une rubrique")
        },
        rubrique(rubrique){
            this.selectedRubrique = rubrique
        },
        addQuestion(){
            const rubriqueCompetence = this.movedList.find(rubrique => rubrique.rubrique === this.selectedRubrique);
            if (rubriqueCompetence) {
                let obj;
                if(this.selectedType == 1 ){
                    obj = {type: this.selectedType, libelle: this.modal.inputQuestion}
                } else if (this.selectedType == 2){
                    obj = {type: this.selectedType, libelle: this.modal.inputQuestion, reponse: this.answers}
                } else if (this.selectedType == 3){
                    obj = {type: this.selectedType, libelle: this.modal.inputQuestion, noteMax: this.modal.maxPoints}
                }
            rubriqueCompetence.questions.push(obj);
            this.modal.inputQuestion = ''
            this.selectedType = '' 
            }
            this.toast.success("Vous venez d'ajouter une nouvelle question")
            console.log(this.movedList)
        },
        removeRubrique(element) {
            const index = this.movedList.findIndex(rubrique => rubrique.rubrique === element);
            if (index !== -1)  {
                this.movedList.splice(index, 1);
            }
            const obj = {rubrique: element, questions: []}
            this.rubriques.push(obj);
        },
        removeQuestion(element, questionT){
            const rubrique = this.movedList.find(rubrique => rubrique.rubrique === element.rubrique);
            if(rubrique){
                const questions = rubrique.questions;
                const indexQuestion = questions.findIndex(question => question === questionT);
                if (indexQuestion !== -1) {
                    questions.splice(indexQuestion, 1)
                }
            }
        }
    }
}   
</script>

<style scoped>

.body{
    background-color: #d1d6d48e;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 500px;
    height: auto;
}

.titre{
    background-color: beige;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid #c2b9b9;
}
h3{
    padding: 2rem;
}
h4{
    padding-top: 1rem;
    padding-right: 2rem;
}
.ctn-qts{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-top: 5px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 5px;
    border-top: solid #c2bcbc9d 2px;
}
.qts1{
    width: 90%;
}
.qts2{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
}
.card{
    height: auto;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;

}
.contenu-questionnaire{
    background-color:blue ;
    width: 80%;
    margin-top: 30px;
    padding: 30px0;
    border:solid #000 1px 2px 3px 4px;
    background-color: #fff;
    display: flex;


    
}


.question{
    cursor: pointer;
    color: red;
}
.question:hover{
    color: rgb(238, 74, 74);
}
.scrollable-content {
    max-height: 400rem;
    overflow-y: auto; 
    overflow-x: hidden; 
  }
  .draggable-list2 {
    background: #1D4851 ;
    color: #fff;
    border: 1px solid;
    height: 50vh;
  }
  .list-item2 {
    margin: 10px;
    padding: 40px;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    display: inline-block;
  }
  
</style>