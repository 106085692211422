
import {createRouter, createWebHistory} from 'vue-router';
import AccueilPage from '@/views/NewAccueil.vue'
import  { authGuard }  from '@/services/auth.js'
import PremierePage from '@/views/PremierePage.vue'
import ConnexionPage from '@/views/Entreprise/ConnexionPage.vue'
import InscriptionPage from '@/views/Entreprise/InscriptionPage.vue'
import Connexion from '@/views/Entreprise/Ressource Humaines/Employes/ConnexionPage';
import DashbordEntreprise from '@/views/Entreprise/DashbordPage.vue';
import ProfileEntreprise from '@/views/Entreprise/ProfilePage.vue';
import CreatePerforme from '@/views/Entreprise/Performance/CreatePerforme.vue';
import RapportPage from '@/views/Entreprise/Performance/RapportsPerformance.vue';
import IndicateurPage from '@/views/Entreprise/Performance/IndicateursPerformance.vue';
import listeDepartement from '@/views/Entreprise/Ressource Humaines/Departement/IndexDepartement.vue';
import listePoste from '@/views/Entreprise/Ressource Humaines/Poste/IndexPoste.vue';
import store from '@/store';
import listeEmploye from '@/views/Entreprise/Ressource Humaines/Employes/IndexEmploye.vue'
import ModalIndic from '@/components/Performance/ModalChart.vue'
import CreateQuestionnaire from '@/views/Entreprise/Ressource Humaines/Questionnaire/CreateQuestionnaire'
import Questionnaire from '@/views/Entreprise/Ressource Humaines/Questionnaire/QuestionnairePage.vue'
import IndexQuestionnaire from '@/views/Entreprise/Ressource Humaines/Questionnaire/IndexQuestionnaire'
import CreateEvalRH from '@/views/Entreprise/Ressource Humaines/CreateEvalRH.vue'
import AddEmploye from '@/views/Entreprise/Ressource Humaines/Employes/CreateEmploye.vue'
import UpdateEmploye from '@/views/Entreprise/Ressource Humaines/Employes/updateEmploye.vue'
import  DashboardEmploye from '@/views/Entreprise/Ressource Humaines/Employes/DashboardPage.vue'
import  ProfileEmployePage from '@/views/Entreprise/Ressource Humaines/Employes/ProfileEmployePage.vue'
import NotFoundPage from '@/views/404Page.vue'

const isConnect = store.getters.isConnect

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/not-found',
            component: NotFoundPage,
            name: 'notFound'
        },
        {
            path: '/dashboard-profile-employe',
            component: ProfileEmployePage,
            name: 'ProfileEmploye',
            meta: {requireAuth: true, type:'employe'},
        },
        {
            path: '/dashboard-employe',
            component: DashboardEmploye,
            name: DashboardEmploye,
            meta: {requireAuth: true, type:'employe'},
        },
        {
            path: '/new-rh',
            component: CreateEvalRH,
            name: 'CreateEvalRH',
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/questionnaire/:quest_id/:token/:employe_id',
            component: Questionnaire,
            props:true,
            name: "Questionnaire",
            // meta: {requireAuth: true, type:'employe'},
        },
        {
            path: '/departement',
            component: listeDepartement,
            name: "liste departement",
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/poste',
            component: listePoste,
            name: "liste Poste",
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/employe',
            component: listeEmploye,
            name: "liste Employe",
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/AddEmploye',
            component: AddEmploye,
            name: "Ajouter Employe",
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/UpdateEmploye/:id',
            component: UpdateEmploye,
            props:true,
            name: "Modifier-Employe",
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/new-questionnaire',
            component: CreateQuestionnaire,
            name: "createQuestionnaire",
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/list-questionnaire',
            component: IndexQuestionnaire,
            name: "listQuestionnaire",
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/',
            component: AccueilPage,
            name: "accueilPage",
        },
        {
            path: '/dashbord-profile-entreprise',
            component:  ProfileEntreprise,
            name:'profile-entreprise',
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/dashbord-new-performance',
            component: CreatePerforme,
            name: 'new-performance',
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/dashbord-entreprise',
            name:'dashbord-entreprise',
            component: DashbordEntreprise,
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/connexion-entreprise',
            component: ConnexionPage,
            name: 'connexion-entreprise',
            beforeEnter: (to, from, next) => {
                if (isConnect ==  "entreprise") {
                    next(to.name = "dashbord-entreprise")
                }else if (isConnect == "employe") {
                    next(to.name = "dashbord-employe")
                }else{
                    next()
                }
            }
        },
        {
            path: '/connexion',
            component: Connexion,
            name: 'connexion-employe'
        },
        {
            path: '/inscription',
            component: InscriptionPage,
            name: 'inscription',
            beforeEnter: (to, from, next) => {
                if (isConnect ==  "entreprise") {
                    next(to.name = "dashbord-entreprise")
                }else{
                    next()
                }
            }
        },
        {
            path: '/connecter',
            component: PremierePage,
            name: 'connecter'
        },
        {
            path: '/rapport-performance',
            component: RapportPage,
            name: 'rapport',
            meta: {requireAuth: true, type:'entreprise'},
        }, 
        {
            path: '/indicateur',
            component: IndicateurPage,
            name: 'indicateur',
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/performance/:id',
            component: IndicateurPage,
            props:true,
            name: 'detail-performance',
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/modal',
            component: ModalIndic,
            name: 'modal-indicateur',
            meta: {requireAuth: true, type:'entreprise'},
        },
        {
            path: '/:pathMatch(.*)*',redirect: '/not-found'
        }
    ]
})

router.beforeEach((to,from,next)=>{
        if(to.meta.requireAuth && to.meta.type == 'entreprise'){
            authGuard('entreprise')
        }
        if(to.meta.requireAuth && to.meta.type == 'employe')
        {
            authGuard('employe')
        }
        next()
})


export default router