
<template>
  <div>
            <Carousel :autoplay="2000" :wrap-around="true" :itemsToShow="3" :transition="500">
              
      
              <Slide v-for="slide in statVal" :key="slide" >
                
                        <div v-if="slide.valeur>0" class="carousel__item card  widget-content">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">{{slide.libelle}}</div>
                                    <div class="widget-subheading">En augmentation</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-success">+<span>{{slide.valeur}}<sup style="font-size: 20px">%</sup></span></div>
                                </div>
                            </div>
                        </div>
                    
                        <div v-else-if="slide.valeur<0" class="carousel__item card  widget-content">
                          <div class="widget-content-wrapper">
                              <div class="widget-content-left">
                                  <div class="widget-heading">{{slide.libelle}}</div>
                                  <div class="widget-subheading">En Baisse</div>
                              </div>
                              <div class="widget-content-right">
                                  <div class="widget-numbers text-danger"><span>{{slide.valeur}}<sup style="font-size: 20px">%</sup></span></div>
                              </div>
                          </div>
                      </div>
                      <div v-else class="carousel__item card  widget-content">
                        <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                                <div class="widget-heading">{{slide.libelle}}</div>
                                <div class="widget-subheading">Constant</div>
                            </div>
                            <div class="widget-content-right">
                                <div class="widget-numbers"><span>{{slide.valeur}}<sup style="font-size: 20px">%</sup></span></div>
                            </div>
                        </div>
                      </div>
                 
                    
              </Slide>
          
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
            <div class="row">
              <div class="col-md-12 col-lg-6">
                  <div class="mb-3 card">
                      <div class="card-header-tab card-header-tab-animation card-header">
                          <div class="card-header-title">
                              <i class="header-icon lnr-apartment icon-gradient bg-love-kiss"> </i>
                              Equilibre Financier
                          </div>
                          
                      </div>
                      <div class="card-body">
                          <div class="tab-content">
                              <div class="tab-pane fade show active" id="tabs-eg-77">
                                  <div class="card mb-3 widget-chart widget-chart2 text-left w-100">
                                      <div class="widget-chat-wrapper-outer">
                                          <div class="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
                                              <DashbordBarGraphe style="height: 350px;" :BFR="BFR" :FR="FR" :TN="TN" :labels="labels"></DashbordBarGraphe>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-12 col-lg-6">
                  <div class="mb-3 card">
                      <div class="card-header-tab card-header">
                          <div class="card-header-title">
                              <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                              Evolution de la Rentabilité
                          </div>
                      </div>
                      <div class="tab-content">
                          <div class="tab-pane fade active show" id="tab-eg-55">
                              <div class="widget-chart p-3">
                                  <div style="height: 350px">
                                    <DashbordGraphe  style="height: 350px;"  :labels="labels" :capros="capros" :engages="engages"> </DashbordGraphe>
                                  </div>
                                  
                              </div>
                             
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                <div class="main-card mb-3 card">
                    <div class="card-header">Evaluation de ressource Huamine
                        
                    </div>
                    <div class="table-responsive">
                        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                            <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th>Nom</th>
                                <th class="text-center">Departement</th>
                                <th class="text-center">Statut</th>
                                <th class="text-center">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="text-center text-muted">#345</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle" src="assets/images/avatars/4.jpg" alt="">
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">John Doe</div>
                                                <div class="widget-subheading opacity-7">Web Developer</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">Informatique</td>
                                <td class="text-center">
                                  <div class="badge badge-success">Terminé</div>
                                </td>
                                <td class="text-center">
                                    <button type="button" id="PopoverCustomT-1" class="btn btn-primary btn-sm">Details</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center text-muted">#51</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle" src="assets/images/avatars/3.jpg" alt="">
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">Jean Agossa</div>
                                                <div class="widget-subheading opacity-7">Controleur Financier</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">Finance</td>
                                <td class="text-center">
                                    <div class="badge badge-success">Terminé</div>
                                </td>
                                <td class="text-center">
                                    <button type="button" id="PopoverCustomT-2" class="btn btn-primary btn-sm">Details</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center text-muted">#2</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle" src="assets/images/avatars/2.jpg" alt="">
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">Elliot Hounvi</div>
                                                <div class="widget-subheading opacity-7">Commercial</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">Marketing</td>
                                <td class="text-center">
                                    <div class="badge badge-danger">En cours</div>
                                </td>
                                <td class="text-center">
                                    <button type="button" id="PopoverCustomT-3" class="btn btn-primary btn-sm">Details</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center text-muted">#5</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle" src="assets/images/avatars/1.jpg" alt=""></div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">Vinnie Hounsa</div>
                                                <div class="widget-subheading opacity-7">Designer</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">Informatique</td>
                                <td class="text-center">
                                  <div class="badge badge-success">Terminé</div>                          </td>
                                <td class="text-center">
                                    <button type="button" id="PopoverCustomT-4" class="btn btn-primary btn-sm">Details</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
          
    
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
//import MainContent from '@/components/DashboardComponent/MainContent.vue';
import DashbordGraphe from '@/components/DashbordEntreprise/DashbordGraphe.vue';
import DashbordBarGraphe from '@/components/DashbordEntreprise/DashbordBarGraphe.vue';
import { performance} from '@/services';
   
export default defineComponent({

  data(){
    return{
      statVal:[],
      labels:[],
      capros:[],
      engages:[],
      BFR:[],
      TN:[],
      FR:[]
    }
  },


  
  components:{
    //'MainContent': MainContent,
    Carousel,
    Slide,
    'DashbordGraphe':DashbordGraphe,
    DashbordBarGraphe,
    Pagination,
   
   
  },
  methods: {

      stat(){
       performance.stats().then((response)=>{
        this.BFR=response.data.BFR
        this.FR=response.data.FR
        this.TN=response.data.TN
          this.statVal=response.data.stat
          this.labels=response.data.labels
          this.capros=response.data.capros
          this.engages=response.data.engages
        }).catch(error=>{
          console.log(error);
        });
        
      },
    },
    mounted() {
    this.stat();
    },
})
</script>
<style scoped>

.carousel__item {
  
  min-height: 80px;
	width: 100%;
	color: black;
	font-size: 14px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
.carousel{
  padding:5px;
}

</style>