<template>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top d-flex align-items-center">
    <div class="container d-flex align-items-center justify-content-between">

      <div class="logo">
        <h1 class="text-light"><a href="index.html"><span>K-PERFORM</span></a></h1>
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html"><img src="../../public/templateaccueil/assets/img/logo.png" alt="" class="img-fluid"></a>-->
      </div>

      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="#hero">Accueil</a></li>
          <li><a class="nav-link scrollto" href="#about">A propos</a></li>
          <li><a class="nav-link scrollto" href="#services">Services</a></li>
          <li><a class="nav-link scrollto" href="#pricing">Tarifs</a></li>
          <li><a class="nav-link scrollto" href="#team">Team</a></li>
          <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
          <li v-if="value == 'faux'">
            <a class="nav-link getstarted scrollto" href="connecter" >
                Se connecter                          
            </a>
        </li>
        <!-- <li v-if="value == 'employe'">
            <router-link :to=" {name:'dashbord-entreprise'} " class="getstarted scrollto">
                Dashboard                           
            </router-link>
        </li> -->
        <li v-if="value == 'entreprise' ">
            <a class="nav-link getstarted scrollto" href="dashbord-entreprise" >
                Dashboard                           
            </a>
        </li>
        <li v-if="value == 'employe' ">
          <a class="nav-link getstarted scrollto" href="dashboard-employe" >
              Dashboard                           
          </a>
      </li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->

  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">

    <div class="container">
      <div class="row gy-4">
        <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1>Bienvenue sur K-PERFORM, la plateforme d'évaluation de la performance.</h1>
          <h2>Nous sommes dédiés à fournir des analyses précises et approfondies pour vous aider à prendre des décisions stratégiques éclairées.</h2>
          <div>
            <a href="#about" class="btn-get-started scrollto">Demarrer</a>
          </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img">
          <img src="../../public/templateaccueil/assets/img/hero-img.svg" class="img-fluid animated" alt="">
        </div>
      </div>
    </div>


  </section><!-- End Hero -->

  <main id="main">

    <!-- ======= About Section ======= -->
    <section id="about" class="about">
      <div class="container">

        <div class="row justify-content-between">
          <div class="col-lg-5 d-flex align-items-center justify-content-center about-img">
            <img src="../../public/templateaccueil/assets/img/features-1.png" class="img-fluid" alt="" data-aos="zoom-in">
          </div>
          <div class="col-lg-6 pt-5 pt-lg-0">
            <h3 data-aos="fade-up">Notre credo est de vous aider à ameliorer la performnce de votre entreprise.</h3>
            <p data-aos="fade-up" data-aos-delay="100">
              Que vous soyez une petite entreprise, une start-up en pleine croissance ou une grande entreprise etablie, K-PERFORM vous offre les outils nécessaires pour evaluer vos performance et prendre des decisions éclairées.
            </p>
            <div class="row">
              <div class="col-md-6" data-aos="fade-up" data-aos-delay="100">
                <i class="bx bx-line-chart"></i>
                <h4>Outils</h4>
                <p>Grace aux informations provenant des etats financiers, à un florilège d'indicateurs financiers et organisationnelles, de questionnaires et d'illustrations, nous analysons la performance des entreprises afin de faciliter la prise de décisions.</p>
              </div>
              <div class="col-md-6" data-aos="fade-up" data-aos-delay="200">
                <i class="bx bx-receipt"></i>
                <h4>Rapports</h4>
                <p>L'exploitation des divers outils conduit à la redaction de rapports explicatifs, suivis de recommandations à mettre en oeuvre en vue d'accoitre la performance.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section><!-- End About Section -->

    <!-- ======= Services Section ======= -->
    <section id="services" class="services section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Services</h2>
          <p>Nos services sont divers et variés</p>
        </div>

        <div class="row">


          <div class="col-md-6 col-lg-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon-box">
              <div class="icon"><i class="bx bx-file"></i></div>
              <h4 class="title"><a href="">Ressources humaines</a></h4>
              <p class="description">Notre service d'evaluation des ressources humaines vous permet d'evaluer la performance de votre equipe, d'identifier les domaines d'amelioration et de prendre des mesures pour optimiser leurs efficacité.Grace à des indicateurs clés de performance et des evaluations personnalisées, vous pourrez ameliorer la productivité , la satisfaction des employés et la retention des talents.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="300">
            <div class="icon-box">
              <div class="icon"><i class="bx bx-tachometer"></i></div>
              <h4 class="title"><a href="">Performance Organisationnnelle</a></h4>
              <p class="description">Notre service d'evaluation de la performance organisationnelle vous permet de mesurer les indicateurs clés de votre entreprise pour une prise de decision éclairée. Vous pourrez ainsi optimiser l'efficacité operationnelle, la rentabilité et la croissance de votre organisation.</p>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Services Section -->

       <!-- ======= Pricing Section ======= -->
       <!-- <section id="pricing" class="pricing">
        <div class="container">
  
          <div class="section-title" data-aos="zoom-out">
            <h2>Tarifs</h2>
            <p>Notre grille tarifaire defie toute concurences</p>
          </div>
  
          <div class="row">
  
            <div class="col-lg-3 col-md-6">
              <div class="box" data-aos="zoom-in">
                <h3>Free</h3>
                <h4><sup>$</sup>0<span> / month</span></h4>
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li class="na">Pharetra massa</li>
                  <li class="na">Massa ultricies mi</li>
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Buy Now</a>
                </div>
              </div>
            </div>
  
            <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
              <div class="box featured" data-aos="zoom-in" data-aos-delay="100">
                <h3>Business</h3>
                <h4><sup>$</sup>19<span> / month</span></h4>
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li>Pharetra massa</li>
                  <li class="na">Massa ultricies mi</li>
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Buy Now</a>
                </div>
              </div>
            </div>
  
            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
              <div class="box" data-aos="zoom-in" data-aos-delay="200">
                <h3>Developer</h3>
                <h4><sup>$</sup>29<span> / month</span></h4>
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li>Pharetra massa</li>
                  <li>Massa ultricies mi</li>
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Buy Now</a>
                </div>
              </div>
            </div>
  
            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
              <div class="box" data-aos="zoom-in" data-aos-delay="300">
                <span class="advanced">Advanced</span>
                <h3>Ultimate</h3>
                <h4><sup>$</sup>49<span> / month</span></h4>
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li>Pharetra massa</li>
                  <li>Massa ultricies mi</li>
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Buy Now</a>
                </div>
              </div>
            </div>
  
          </div>
  
        </div>
      </section> -->
      <!-- End Pricing Section -->

    <!-- ======= Team Section ======= -->
    <section id="team" class="team">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Equipe</h2>
          <p>Notre equipe, pour vous accompagné</p>
        </div>

        <div class="row">

          <div class="col-xl-3 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
            <div class="member">
              <img src="../../public/templateaccueil/assets/img/team/dg.png" class="img-fluid" alt="">
              <div class="member-info">
                <div class="member-info-content">
                  <h4>Gibran NANKPAN</h4>
                  <span>Directeur Général</span>
                </div>
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
            <div class="member">
              <img src="../../public/templateaccueil/assets/img/team/chris.jpg" class="img-fluid" alt="">
              <div class="member-info">
                <div class="member-info-content">
                  <h4>Christobal ASSOGBA</h4>
                  <span>Responsable recherche et développement</span>
                </div>
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="300">
            <div class="member">
              <img src="../../public/templateaccueil/assets/img/team/fabienne.jpg" class="img-fluid" alt="">
              <div class="member-info">
                <div class="member-info-content">
                  <h4>Fabienne DOSSEH</h4>
                  <span>Responsable des opérations</span>
                </div>
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="400">
            <div class="member">
              <img src="../../public/templateaccueil/assets/img/team/gilberte.jpg" class="img-fluid" alt="">
              <div class="member-info">
                <div class="member-info-content">
                  <h4>Gilberte AGBOSSAGA</h4>
                  <span>Assistante Commerciale</span>
                </div>
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Team Section -->

    <!-- ======= Contact Us Section ======= -->
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Contactez nous</h2>
          <p>Pour plus d'informations</p>
        </div>

        <div class="row">

          <div class="col-lg-5 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Adresse:</h4>
                <p> Ilot numéro 742, Quartier Gbegamey Nord Immeuble Bhelix - <strong>Bénin</strong></p>
                <p> Tokoin Doumassèssè - IMMEUBLE SAM Rue des balises, Bd de la kara - <strong>Togo</strong></p>
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>info@kapitalhc.com</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Téléphone:</h4>
                <p>(+229) 99915050 <br>
                  (+228) 92040435
                </p>
              </div>
              
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d991.3022606266359!2d2.4071661104253144!3d6.366987332436733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x102355493eb64b13%3A0x3a9d1c176632a1a6!2sHOLDING%20BOURJON%20INVESTMENT!5e0!3m2!1sen!2sbj!4v1689185816356!5m2!1sen!2sbj" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe>
            </div>

          </div>

          <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <form action="" @submit.prevent="contactUs" method="post" role="form" class="php-email-form">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="name">Votre Nom</label>
                  <input v-model="infos.nom" type="text" class="form-control" id="name" placeholder="Votre Name" required>
                </div>
                <div class="form-group col-md-6 mt-3 mt-md-0">
                  <label for="name">Votre Email</label>
                  <input v-model="infos.email" type="email" class="form-control"  id="email" placeholder="Votre Email" required>
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="name">Sujet</label>
                <input v-model="infos.sujet" type="text" class="form-control" id="subject" placeholder="Sujet" required>
              </div>
              <div class="form-group mt-3">
                <label for="name">Message</label>
                <textarea v-model="infos.content" class="form-control" rows="10" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Chargement</div>
                <div class="error-message"></div>
                <div class="sent-message">Votre message a été envoyé. Merci!</div>
              </div>
              <div class="text-center"><button type="submit">Soumettre</button></div>
            </form>
          </div>

        </div>

      </div>
    </section><!-- End Contact Us Section -->
  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer">

    <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <h4>Rejoignez notre Newsletter</h4>
            <p>Rester informé à tout moment et en tout lieu.</p>
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Subscribe">
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-contact">
            <h3>K-PERFORM</h3>
            <p>
              Ilot numéro 742,<br>
              Quartier Gbegamey Nord Immeuble Bhelix <br>
              Bénin<br><br>
              <strong>Phone Benin:</strong> (+229) 99915050 <br>
              <strong>Phone Togo:</strong> (+228) 92040435 <br>
              <strong>Email:</strong> info@kapitalhc.com<br>
            </p>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Liens Utiles</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Accueil</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">A propos</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Services</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Equipe</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Tarifs</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Nos Services</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Performance organisationnelle</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Ressources Humaines</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Nos Reseaux Sociaux</h4>
            <p>Rejoignez nous sur nos differents caneaux</p>
            <div class="social-links mt-3">
              <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
              <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
              <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container py-4">
      <div class="copyright">
        &copy; Copyright <strong><span>K-PERFORM</span></strong>. Tout droits reservés
      </div>
      <div class="credits">
        <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/ninestars-free-bootstrap-3-theme-for-creative/ -->
        Designed by <a href="https://bootstrapmade.com/">KHC DevsLab</a>
      </div>
    </div>
  </footer><!-- End Footer -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
</template>


<script>
import AOS from 'aos'
import store from '@/store'
import { rh } from "@/services";
import Swal from 'sweetalert2'

export default {

  name: 'accueilPage',
  data() {
        return {
            user: store.getters.getUser,
            type: store.getters.getType,
            token: store.getters.getToken,
            value:'faux',
            infos: {
              email: '',
              nom: '',
              content: '',
              sujet: ''
            },
        }
    },
    methods: {
        islogged(){
            if (this.user != null && this.token != null && this.type == 'entreprise') {
                this.value = 'entreprise'
            }else if (this.user != null && this.token != null && this.type == 'employe') {
                this.value = 'employe'
            } else if (this.user == null && this.token == null && this.type == null) {
                this.value = 'faux'
            }
        },
        contactUs(){
          rh.contactUs(this.infos).then((response) => {
            console.log(response)
            if(response.data.success){
              Swal.fire('Envoyé', 'Votre mail a été envoyé avec succès', 'success')
            }
          }).catch((error) =>{
            console.log(error)
          })
        }
    },
   
  mounted() {
    this.islogged();
    AOS.init({});
  // Créez un élément <script>


  const script2 = document.createElement('script');
  script2.src = 'templateaccueil/assets/vendor/aos/aos.js';
  script2.type='text/javascript';

  const script3 = document.createElement('script');
  script3.src = 'templateaccueil/assets/vendor/bootstrap/js/bootstrap.bundle.min.js';
  script3.type='text/javascript';

  const script4 = document.createElement('script');
  script4.src = 'templateaccueil/assets/vendor/glightbox/js/glightbox.min.js';
  script4.type='text/javascript';

  const script5 = document.createElement('script');
  script5.src = 'templateaccueil/assets/vendor/isotope-layout/isotope.pkgd.min.js';
  script5.type='text/javascript';

  const script6 = document.createElement('script');
  script6.src = 'templateaccueil/assets/vendor/swiper/swiper-bundle.min.js';
  script6.type='text/javascript';

  const script7 = document.createElement('script');
  script7.src = 'templateaccueil/assets/vendor/php-email-form/validate.js';
  script7.type='text/javascript';

  const script8 = document.createElement('script');
  script8.src = 'templateaccueil/assets/js/main.js';
  script8.type='text/javascript';
  // Ajoutez l'élément <script> au corps de la page
 

  document.body.appendChild(script2);
  document.body.appendChild(script3);
  document.body.appendChild(script4);
  document.body.appendChild(script5);
  document.body.appendChild(script6);
  document.body.appendChild(script7);
    document.body.appendChild(script8);
}


}



</script>

<style scoped>
a{
  text-decoration:none;
}
    @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,600,600i,700,700i');

    @import url('../../public/templateaccueil/assets/vendor/aos/aos.css');
    @import url('../../public/templateaccueil/assets/vendor/bootstrap/css/bootstrap.min.css');

    @import url('../../public/templateaccueil/assets/vendor/bootstrap-icons/bootstrap-icons.css');
    @import url('../../public/templateaccueil/assets/vendor/boxicons/css/boxicons.min.css');

    @import url('../../public/templateaccueil/assets/vendor/glightbox/css/glightbox.min.css');
    @import url('../../public/templateaccueil/assets/vendor/swiper/swiper-bundle.min.css');
    @import url('../../public/templateaccueil/assets/css/style.css');
</style>
