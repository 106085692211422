<template>
        <div class="row">
            <div class="col-md-12">
                <div class="main-card mb-3 card">
                    <div class="card-body">
                        <div class="card-title">Employés</div>
                        <!-- Button trigger modal -->
                            <router-link type="button" class="btn mr-2 mb-2 btn-primary" to="/AddEmploye">
                                <i class="fa fa-fw" aria-hidden="true" title="Copy to use plus"></i> Ajouter un employé
                            </router-link>
                        <table class="mb-0 table table-hover">
                            <thead>
                            <tr class="text-center">
                                <th>#</th>
                                <th>Nom</th>
                                <th>Sexe</th>
                                <th>Email</th>
                                <th>Telephone</th>
                                <th>Adresse</th>
                                <th>Departement</th>
                                <th colspan="2">Action</th>
                            </tr>
                            </thead>
                            <tbody class="text-center" v-if="employes.length==0"> <tr><td colspan="9">Aucun Employe Enregistré</td></tr></tbody>

                            <tbody v-else>
                                
                                <tr class="text-center" v-for="(item, index) in employes" :key="item.id">
                                    <th scope="row">{{index + 1}}</th>
                                    <td>
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">{{item.nom}}</div>
                                                    <div class="widget-subheading opacity-7">{{item.prenom}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{item.sexe}}</td>
                                    <td>{{item.email}}</td>
                                    <td>{{item.telephone}}</td>
                                    <td>{{item.adresse}}</td>
                                    <td>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{item.departement_libelle}}</div>
                                            <div class="widget-subheading opacity-7">{{item.poste_libelle}}</div>
                                        </div>
                                    </td>
                                    <td>
                                            <router-link type="button" class="btn mr-2 mb-2 btn-primary" :to=" {name: 'Modifier-Employe', params:{id: item.id}} "> <i class="nav-link-icon fa fa-edit"></i>  Modifier</router-link>
                                           
                                    </td>
                                    <td><button  type="button" @click="supprimer(item.id)" class="btn mr-2 mb-2 btn-danger">
                                        <i class="fa fa-fw" aria-hidden="true" title="Copy to use trash"></i> Supprimer
                                    </button></td>
                                </tr>
                            
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
       
     
        <!-- Modal -->
        

            
        
   
</template>

<script>

import { rh } from '@/services';
import store from '@/store';
import Swal from 'sweetalert2'
export default {
name:'listeEmploye',
data() {
    return {
        employes:[],
        employeId:'',
        id:store.getters.getUser.id,
    }
},

components:{
    
},
mounted() {
    rh.allEmploye(this.id).then((response)=>{
        this.employes=response.data.employes
    })
},
methods: {
    supprimer(id){
        Swal.fire({
                title: 'Êtes vous sûr?',
                text: "Ce Employé sera définitivement retiré de la liste du personnel",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, supprimer!',
                cancelButtonText:'Annuler'
            }).then((choice)=>{
                if(choice.isConfirmed){
                    rh.deleteEmploye(id).then(
                    this.updateEmploye(),
                    Swal.fire(
                                'Supprimé!',
                                ' L\'employé a bien été supprimé',
                                'success'
                            )
                    ).catch(err => {
                        console.log(err);
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: err,
                                    })
                    })
                }
            })
       
    }
},
}
</script>

<style>


</style>