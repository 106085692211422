<template>
        <section v-if="step == 1" class="container pt-3 mb-3">
            <h3 class="mb-4">Choissisez le type d'évaluation</h3>
            
            <div class="row pt-5 mt-30">
                <div class="col-lg-4 col-sm-6 mb-30 pb-5">
                    <a @click="selected" class="card" href="#">
                        <div class="box-shadow bg-white rounded-circle mx-auto text-center" style="width: 90px; height: 90px; margin-top: -45px;"><i class="fa fa-car fa-3x head-icon"></i></div>
                        <div class="card-body text-center">
                            <h3 class="card-title pt-1">Evaluation par les paires</h3>
                            <p class="card-text text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-sm-6 mb-30 pb-5">
                    <a @click="selected" class="card" href="#">
                        <div class="box-shadow bg-white rounded-circle mx-auto text-center" style="width: 90px; height: 90px; margin-top: -45px;"><i class="fa fa-camera fa-3x head-icon"></i></div>
                        <div class="card-body text-center">
                            <h3 class="card-title pt-1">Evaluation 360 degré</h3>
                            <p class="card-text text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-sm-6 mb-30 pb-5">
                    <a @click="selected" class="card" href="#">
                        <div class="box-shadow bg-white rounded-circle mx-auto text-center" style="width: 90px; height: 90px; margin-top: -45px;"><i class="fa fa-image fa-3x head-icon"></i></div>
                        <div class="card-body text-center">
                            <h3 class="card-title pt-1">Evaluation directe</h3>
                            <p class="card-text text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                        </div>
                    </a>
                </div>
            </div>
            <button v-if="select" class="btn btn-success" @click="goStep(2)" > Suivant </button>
        </section>

        <section class="container pt-3 mb-3" v-if="step == 2" >
            
            <h3 class="mb-4">Choissisez le(s) employé(s) à évaluer</h3>
            
            <VueGoodTable
            @selected-rows-change="selectionChanged"
            :columns="columns"
            :select-options="{
                enabled: true,
                selectionText: 'employé selectionné(s)',
                clearSelectionText: 'effacer',
            }"
            :rows="employes">
            </VueGoodTable>

            <button v-if="select" class="btn btn-success" @click="goStep(3)" > Suivant </button>
        </section>

        <section v-if="step == 3 " >
            <h2>Choisir le questionnaire</h2>

            <table class="table">
                <thead class="text-center">
                <tr>
                    <th></th>
                    <th>Libellé </th>
                    <th>Date de création </th>
                </tr>
                </thead>
                <tbody class="text-center">
                <tr v-for="item in questionnaires" :key="item.id">
                    <td><input type="radio" v-model="selectedQuestionnaire" :value="item.id"></td>
                    <td>{{ item.reference }}</td>
                    <td> {{ moment(item.created_at).format('YYYY-MM-DD à HH:mm:ss') }}</td>
                </tr>
                </tbody>
            </table>
            <button v-if="select" class="btn btn-success" @click="goStep(4)" > Suivant </button>
        </section>

        <section v-if="step == 4">
            <h2>Choisir les employés habilités a participer à cette Evaluation</h2>
            
            <VueGoodTable
            @selected-rows-change="selectionChange"
            :columns="columns"
            :select-options="{
                enabled: true,
                selectionText: 'participant selectionné(s)',
                clearSelectionText: 'effacer',
            }"
            :rows="employes">
            </VueGoodTable>


            <button class="btn btn-success" @click="createEvalRh" > Finaliser</button>
        </section>
</template>

<script>

import { VueGoodTable } from 'vue-good-table-next';
import {rh} from '@/services'
import store from '@/store';
import moment from 'moment'
import Swal from 'sweetalert2';

export default {
    components: {
        VueGoodTable
    },
    data() {
        return {
            select: false,
            step: 1,
            employes: [],
            rowParticipantSelection: [],
            rowSelection: [],
            questionnaires: [],
            selectedQuestionnaire: '',
            userId: store.getters.getUser.id,
            columns: [
                {
                label: 'Nom',
                field: 'nom',
                },
                {
                label: 'Prenom',
                field: 'prenom',
                },
                {
                label: 'Sexe',
                field: 'sexe',
                },
                {
                label: 'Email',
                field: 'email',
                },
                {
                label: 'Telephone',
                field: 'telephone',
                type: 'number',
                },
                {
                    label: 'Adresse',
                    field: 'adresse',
                },
                {
                    label: 'Poste',
                    field: 'poste_libelle'
                }
            ],
        }
    },
    mounted() {
        rh.allEmploye(this.userId).then((response)=>{
            this.employes = response.data.employes
            console.log(this.employes);
        })
        rh.listeQuestionnaire(store.getters.getUser.id).then((response) =>{
            this.questionnaires = response.data.questionnaire
            console.log(response)
        })
    },
    methods: {
        masquerMessage() {
            Swal.close();
        },
        afficherMessage() {
            Swal.fire({
                title: 'Traitement en cours',
                text: 'Veuillez patienter...',
                allowOutsideClick: false,
            });
            Swal.showLoading();
          },
        moment(date) {
                return moment(date);
            },
        selected(){
            this.select = true
        },
        goStep(step){
            this.step = step
        },
        selectionChange(params) {
            this.rowParticipantSelection = params.selectedRows
        },
        selectionChanged(params) {
            this.rowSelection = params.selectedRows;
        },
        async createEvalRh(){
            this.afficherMessage();
            await rh.newEvalRh(this.userId, this.selectedQuestionnaire, this.rowSelection, this.rowParticipantSelection).then((response) => {
                console.log(response)
                this.masquerMessage();
            })
            .catch((error) => {
                console.log(error)
                this.masquerMessage();
            })
            this.$router.push({ name: 'dashbord-entreprise'})
        }
    },
}
</script>

<style scoped>

body{
    margin-top:20px;
    background:#eee;
}
.card:focus{
    border: 2px solid #1D4851;
}

.card:hover{
    border: 2px solid #1D4851;
}

a.card {
    text-decoration: none;
}

.card {
    position: relative;
    border: 0;
    border-radius: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09);
    box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09);
}
.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: .25rem;
}

.box-shadow {
    -webkit-box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09) !important;
    box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09) !important;
}

.ml-auto, .mx-auto {
    margin-left: auto !important;
}
.mr-auto, .mx-auto {
    margin-right: auto !important;
}
.rounded-circle {
    border-radius: 50% !important;
}
.bg-white {
    background-color: #fff !important;
}

.ml-auto, .mx-auto {
    margin-left: auto !important;
}

.mr-auto, .mx-auto {
    margin-right: auto !important;
}
.d-block {
    display: block !important;
}
img, figure {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.card-text {
    padding-top: 12px;
    color: #000;
}

.text-sm {
    font-size: 12px !important;
}
p, .p {
    margin: 0 0 16px;
}

.card-title {
    margin: 0;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 900;
    color: #1D4851;
}

.pt-1, .py-1 {
    padding-top: .25rem !important;
}

.head-icon{
    margin-top:18px;
    color:#c9552b
}

</style>