
<template>
            <form @submit.prevent="updateProfile" class="row" method="post">
                <div class="row">
                   <!-- <div class="col-md-3">
                        <div class="card card-primary card-outline">
                            <div class="card-body box-profile">
                            <div class="text-center">
                                <img class="profile-user-img img-fluid img-circle"
                                    src="@/assets/img/user4-128x128.jpg"
                                    alt="User profile picture">
                            </div>
                            <h3 class="profile-username text-center">{{state.entreprise.denomination}}</h3>
                            <p class="text-muted text-center">Entreprise</p>
            
                            <ul class="list-group list-group-unbordered mb-3">
                                <li class="list-group-item">
                                <b>Followers</b> <span class="float-right">1,322</span>
                                </li>
                                <li class="list-group-item">
                                <b>Following</b> <span class="float-right">543</span>
                                </li>
                                <li class="list-group-item">
                                <b>Friends</b> <span class="float-right">13,287</span>
                                </li>
                                <li class="list-group-item">
                                    <b>Friends</b> <span class="float-right">13,287</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>-->
            
                    <div class="col-md-12">
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Modifier vos informations</h3>
                            </div>
                            
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputText">Denomination </label>
                                                <input v-model="state.entreprise.denomination"  type="text" class="form-control" id="exampleInputText"  placeholder="Dénomination">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Email address  </label>
                                                <input v-model="state.entreprise.email" type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputIfu">IFU </label>
                                                <input class="form-control" id="exampleInputIfu" type="number"  v-model="state.entreprise.ifu"  placeholder="N° IFU">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputRccm">RCCM</label>
                                                <input class="form-control" type="text" id="exampleInputRccm"  v-model="state.entreprise.rccm"  placeholder="Registre de Commerce">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputTaille">Taille de l'entreprise  </label>
                                                <select class="form-select mb-3" id="exampleInputTaille">
                                                    <option selected disabled >Nombre d'employé</option>
                                                    <option value="startup">1-19</option>
                                                    <option value="petite">20-99</option>
                                                    <option value="moyenne">100-499</option>
                                                    <option value="grande">Plus de 500</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputAdresse">Adresse  </label>
                                                <input v-model="state.entreprise.adresse" type="text" class="form-control" id="exampleInputAdresse"  placeholder="Adresse">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Mot de passe  </label>
                                                <input v-model="state.entreprise.password" class="form-control" type="password" name="" id="exampleInputPassword1">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword2">Confirmer Mot de passe  </label>
                                                <input v-model="state.confirm_password" class="form-control" type="password" name="" id="exampleInputPassword2">
                                            </div>
                                            <span class="error  mt-0" v-if="v$.confirm_password.$error" >
                                                {{ v$.confirm_password.$errors[0].$message }}  
                                            </span>
                                        </div>
                                    </div>
                                    <div style="text-align:center">
                                        <button type="submit" class=" btn button">Modifier</button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </form>
</template>

<script>

import {account} from '@/services'
import { useVuelidate } from '@vuelidate/core'
import { sameAs, helpers } from '@vuelidate/validators'
import {reactive, computed} from 'vue';
import store from '@/store'

export default{
    data() {
        return {
            user: store.getters.getUser
        }
    },
    components:{
    },
    setup(){
        const state =  reactive({
            entreprise:{
                denomination: store.getters.getUser.denomination,
                email: store.getters.getUser.email,
                taille: store.getters.getUser.taille,
                adresse: store.getters.getUser.adresse,
                password: store.getters.getUser.password,
                ifu: store.getters.getUser.ifu,
                rccm: store.getters.getUser.rccm,
            },
            confirm_password: store.getters.getUser.password,
            userId: store.getters.getUser.id,
        })

        const rules = computed(() => {
            return {
            entreprise: {
                },
                confirm_password: {
                    sameAs: helpers.withMessage("Les mots de passe ne sont pas identiques", sameAs(state.entreprise.password )),
                },
            }
        })
        const v$ = useVuelidate(rules, state)
        return {
            state,
            v$
        }
    },
    methods: {
        updateProfile: function(){
        this.v$.$validate()
        account.updateProfileEntreprise(this.state.entreprise, this.state.userId ).then((response) => {
            const user = response.data.user;
            store.commit('setUser', user)
            this.$router.go()
            })
        },
    },
}
</script>

<style scoped>
.required{
    color: red;
}
.button{
    text-align: center;
    color: white;
    background-color: #eb5d1e;
}
</style>