
    <template>
        <table class="table table-striped">
        <thead>
            <tr>
            <th style="width: 10px">ID</th>
            <th>Libellé</th>
            <th>Date</th>
            <th colspan="2">Action</th>
            </tr>
        </thead>
        <tbody v-if="data.length==0">
            <tr><td colspan="4"> Aucune evaluation disponible</td></tr>
        </tbody>
        <tbody v-else>
            <!-- Ajoutez une boucle pour afficher les données -->
            <tr v-for="item in data" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.reference}}</td>
            <td>{{ moment(item.created_at).format('YYYY-MM-DD à HH:mm:ss') }}</td>
            <td>
                <div class="text-center">

                <router-link class="btn btn-primary" :to=" {name: 'detail-performance', params:{id: item.id}} " title="Details">
                    Details
                </router-link>
                </div>
            </td>
            <td> 
                <div class="text-center">
                <a  class="btn btn-danger" href="#">
                    <span @click="deletePerform(item.id)" >Supprimer</span>
                </a>
                </div>
            </td>
            </tr>
        </tbody>
        
        </table>
    </template>
    
    <script>

    import {performance} from '@/services'
    import store from '@/store';
    import moment from 'moment';
    import Swal from 'sweetalert2'




    export default {
        data() {
        return {
            data: [],
            userId: store.getters.getUser.id
        };
        },
        methods: {
            deletePerform(id){
                Swal.fire({
                title: 'Êtes vous sûr?',
                text: "Ce performance sera définitivement supprimé",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, supprimer!',
                cancelButtonText:'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    performance.deletePerform(id).then((response) => {
                        console.log(response);
                            Swal.fire(
                                'Supprimé!',
                                'La performance a bien été supprimé',
                                'success'
                            )
                    }).catch(err => {
                        console.log(err);
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: err,
                                    })
                    })
                }
            })
            },
            moment(date) {
                return moment(date);
            }
        },
        mounted(){
            performance.getPerformances().then((response) => {
                this.data = response.data.performances 
            })
        }
    };
    </script>  

    <style scoped>
    tr{
        text-align: center;
     }
    </style>