<template>

        <div class="row">
            <div class="col-md-12">
                <div class="main-card mb-3 card">
                    <div class="card-body">
                        <div class="card-title">Departements</div>
                        <!-- Button trigger modal -->
                        <button type="button" class="btn mr-2 mb-2 btn-primary" data-toggle="modal" data-target="#CreateModal">
                            <i class="fa fa-fw" aria-hidden="true" title="Copy to use plus"></i> Ajouter un departement
                        </button>
                        <table class="mb-0 table table-hover">
                            <thead>
                            <tr class="text-center">
                                <th>#</th>
                                <th>Libellé</th>
                                <th>Employés</th>
                                <th colspan="2">Action</th>
                            </tr>
                            </thead>
                            <tbody class="text-center" v-if="departements.length==0"> <tr><td colspan="5">Aucun Departement Enregistré</td></tr></tbody>
                            <tbody v-else>
                                
                                <tr class="text-center" v-for="(departement,index) in departements" :key="departement.id">
                                    <th scope="row">{{index + 1}}</th>
                                    <td>{{departement.libelle}}</td>
                                    <td>200</td>
                                    <td>
                                        <button  type="button" @click="modifier(departement.id)" class="btn mr-2 mb-2 btn-primary" data-toggle="modal" data-target="#updateModal">
                                            <i class="nav-link-icon fa fa-edit"></i>  Modifier
                                        </button>
                                    </td>
                                    <td><button  type="button" @click="supprimer(departement.id)" class="btn mr-2 mb-2 btn-danger">
                                        <i class="fa fa-fw" aria-hidden="true" title="Copy to use trash"></i> Supprimer
                                    </button></td>
                                </tr>
                            
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Teleport to="body">
            <CreateDepartement @updateDepartement="updateDepartement"></CreateDepartement>
        <UpdateDepartement :id="departementId" @modifierDepartement="updateDepartement"></UpdateDepartement>
        </Teleport>
        
     
        <!-- Modal -->
    
</template>

<script>
import CreateDepartement from './CreateDepartement.vue';
import updateDepartement from './updateDepartement.vue';
import { rh } from '@/services';
import store from '@/store';
import Swal from 'sweetalert2'
export default {
name:'listeDepartement',
data() {
    return {
        departements:[],
        departementId:'',
        id:store.getters.getUser.id,
    }
},

components:{
    'CreateDepartement':CreateDepartement,
    'UpdateDepartement':updateDepartement
},
mounted() {
    rh.allDepartement(this.id).then((response)=>{
        this.departements=response.data.departements 
    })
},
methods: {
    updateDepartement(){
        rh.allDepartement(this.id).then((response)=>{
        this.departements=response.data.departements 
    })
    },
    modifier(id){
        this.departementId=id
    },
    supprimer(id){
        Swal.fire({
                title: 'Êtes vous sûr?',
                text: "Ce departement sera définitivement supprimé",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, supprimer!',
                cancelButtonText:'Annuler'
            }).then((choice)=>{
                if(choice.isConfirmed){
                    rh.deleteDepartement(id).then(
                    this.updateDepartement(),
                    Swal.fire(
                                'Supprimé!',
                                'Le departement a bien été supprimé',
                                'success'
                            )
                    ).catch(err => {
                        console.log(err);
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: err,
                                    })
                    })
                }
            })
       
    }
},
}
</script>

<style>

</style>