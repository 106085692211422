
/* eslint-disable */

import Axios from "./caller";

let newQuestionnaire = (questionnaire, reference, userId) =>{
    return Axios.post('/newQuestionnaire',{
        questionnaire, reference, userId
    })
}

let addEmploye=(employe)=>{
    return Axios.post('/employe',{
        nom:employe.nom,
        prenom:employe.prenom,
        sexe:employe.sexe,
        naissance:employe.naissance,
        telephone:employe.telephone,
        email:employe.email,
        adresse:employe.adresse,
        poste_id:employe.poste_id,
        user_id:employe.user_id
    });
}
 
let allEmploye = (id)=>{
    return Axios.post('/employe/liste',{
        id
    })
}

let fetchEmploye = (id) => {
    return Axios.post('/employe/fetch',{
        id
    })
}

let updateEmploye = (id, employe) => {
    return Axios.patch('/employe/'+ id, {
        employe,
    })
}

let deleteEmploye = (index) => {
    return Axios.delete('/employe/'+index)
}

let newPoste = (poste) => {
    return Axios.post('/poste',{
        libelle:poste.libelle,
        description:poste.description,
        departement_id:poste.departement_id
    })
}

let allPoste = (id) => {
    return Axios.post('/poste/liste',{
        id
    })
}

let updatePoste = (id,poste) => {
    return Axios.put('/poste/'+ id ,{
        poste
    })
}

let deletePoste = (index) => {
    return Axios.delete('/poste/'+index)
}

let fetchPoste = (id)=>{
    return Axios.post('/poste/fetch',{
        id
    })
 }

 let posteSpecifique = (id)=>{
    return Axios.post('/poste/specifique',{
        id
    })
 }

let newDepartement = (departement, userId) => {
    return Axios.post('/departement',{
        libelle:departement.libelle,
         id:userId
        })
}

let allDepartement = (userId) => {
    return Axios.post('/departement/liste', {
        id:userId,
    })
}

let updateDepartement = (id, departement) => {
    return Axios.patch('/departement/'+ id, {
        departement,
    })
}
 let fetchdepartement = (id)=>{
    return Axios.post('/departement/fetch',{
        id
    })
 }

let deleteDepartement = (index) => {
    return Axios.delete('/departement/'+index)
}

let allTypeQuestion = () => {
    return Axios.get('/allTypeQuestions')
}

let  newEvalRh = (userId, questionnaireId, employes, cibles) => {
    return Axios.post('/newEvalRh', {
        adressFront: process.env.VUE_APP_FRONT+'/questionnaire/',
        userId: userId,
        questionnaireId: questionnaireId,
        employes: employes,
        cibles: cibles
    })
}

let evalRHemploye = (employeId) => {
    return Axios.post('/evalRHemploye', {
        employeId
    })
}

let getQuestionnaire = (questionnaire_id, userId) => {
    return Axios.post('/getQuestionnaire', {
        questionnaire_id, userId
    })
}

let listeQuestionnaire = (userId) => {
    return Axios.post('/listeQuestionnaire', {userId})
}

let contactUs = (infos) => {
    return Axios.post('/contactUs', {
        content: infos.content,
        sujet: infos.sujet,
        email: infos.email,
    })
}

let storeReponse = (evalRh_id, employe_id, participant_id) => {
    return Axios.post('/storeReponse', {

    })
}


export const rh = { 
    newQuestionnaire, newPoste, allPoste, updatePoste, deletePoste,posteSpecifique, newDepartement,
    allDepartement, updateDepartement, deleteDepartement,addEmploye,fetchdepartement,
    fetchPoste, allEmploye, fetchEmploye, updateEmploye,deleteEmploye,allTypeQuestion, newEvalRh,
    listeQuestionnaire, evalRHemploye, getQuestionnaire,contactUs, storeReponse
};
