
import axios from 'axios'
import store from '@/store'
const accessToken = store.getters.getToken

const Axios = axios.create({
    // withCredentials: true,
    timeout: 60 * 1000, // Timeout
    headers: {
        common:{
            Accept: "application/json",
            "Content-Type": "application/json,multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
        },
    },
    baseURL: process.env.VUE_APP_LARAVELAPI,
    withCredentials: true,
})

Axios.interceptors.request.use((config) => {
    config.headers['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    return config;
  });

export default Axios