
import { createApp } from 'vue'
import router from '@/router'
import store from '@/store';
import Vuelidate from '@vuelidate/core'
import Toast from "vue-toastification";
import App from './App.vue'
import XLSX from 'xlsx'
import 'bootstrap'
import "vue-toastification/dist/index.css"
import VueGoodTablePlugin from 'vue-good-table-next';

// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faDownload, faEye, faFileLines, faPaperPlane, faTrash, faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret,faEye,faTrash,faDownload,faFileLines,faPaperPlane)

const app = createApp(App);
app.use(router,store, XLSX, Vuelidate, VueGoodTablePlugin);
app.use(Toast);
app.component('font-awesome-icon', FontAwesomeIcon);
app.headers = {
    'Content-Type': 'application/javascript',
    };
app.mount('#app'); 
