<template>

    <BarGraph :data="chartData" :options="chartOptions"/>
      
</template> 

<script>
import BarGraph from '@/components/Performance/Graphe/BarChart.vue';

export default {
  name: 'DashbordBarGraphe',
  components: {
    BarGraph
  },
  props:["labels","BFR","FR","TN"],
  data() {
    return {
      chartData: null,
      chartOptions: {
        responsive: true,      
        maintainAspectRatio: false, // Allow the chart to take the full width and height of its container
        
        scales: {
          x: {
            display:true,
          grid: {
            display: false
          },
          ticks: {
                    display: true
                }
        },
        y: {
          display:true,
          grid: {
            display: false
          },
          ticks: {
                    display: true
                }

        },
        },
      }
    }
  },
  methods:{
    updateChartData(){
      this.chartData={
      labels:this.labels,
        datasets: [
          {
            label: 'Besoin en Fond de Roulment',
            data:this.BFR,
            fill: false,
            tension: 0.4,
            backgroundColor: 'rgba(54, 162, 235)',
            borderColor: 'rgba(54, 162, 235)',
          },
          {
            label: 'Fond de Roulement',
            data:this.FR,
            fill: false,
            tension: 0.4,
            backgroundColor: 'rgba(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132)',
          },
          {
            label: 'Tresorerie Nette',
            data:this.TN,
            fill: false,
            tension: 0.4,
            backgroundColor: 'rgba(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132)',
          }  
        ]
    }
    }
    
  },
  watch:{
    propsData:{
      immediate:true,
      handler(){
        this.updateChartData();
      }
    }
  },
  updated(){
    this.updateChartData();  
  } 
  
}
</script>

<style scoped>

</style>