<template>
    <div class="app-sidebar sidebar-shadow">
        <div class="app-header__logo">
            <div class="logo-src"></div>
            <div class="header__pane ml-auto">
                <div>
                    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="app-header__mobile-menu">
            <div>
                <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div class="app-header__menu">
            <span>
                <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span class="btn-icon-wrapper">
                        <i class="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
        </div>    
        <div class="scrollbar-sidebar">
            <div class="app-sidebar__inner">
                <ul class="vertical-nav-menu">
                    <li class="app-sidebar__heading">Tableau de Bord</li>
                    <li>
                        <router-link ref="bouton1" :class="{'mm-active': boutonActif==1}" @click="selectBtn(1)" to="/dashbord-employe">
                            <i class="metismenu-icon pe-7s-display2"></i>
                            Tableau de Bord
                        </router-link>
                    </li>
                    <li class="app-sidebar__heading">Evaluations</li>
                    <li>
                        <a href="#">
                            <i class="metismenu-icon pe-7s-graph1"></i>
                            Résultats et évaluations
                            <i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                        </a>
                        <ul>
                            <li>
                                <router-link ref="bouton2" :class="{'mm-active': boutonActif==2}"  @click="selectBtn(2)"  to="/dashbord-new-performance">
                                    <i class="metismenu-icon"></i>
                                    Resultats et recommandations
                                </router-link>
                            </li>
                            <li>
                                <router-link ref="bouton3" :class="{'mm-active': boutonActif==3}"  @click="selectBtn(3)"  to="/rapport-performance">
                                    <i class="metismenu-icon">
                                    </i>Evaluation en cours
                                </router-link>
                            </li>
                            <li>
                                <router-link ref="bouton3" :class="{'mm-active': boutonActif==3}"  @click="selectBtn(3)"  to="/rapport-performance">
                                    <i class="metismenu-icon">
                                    </i>S'auto-évaluer
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="app-sidebar__heading">Suggestion</li>
                    <li>
                        <router-link ref="bouton11" to="#" :class="{'mm-active': boutonActif==11}" @click="selectBtn(11)">
                            <i class="metismenu-icon pe-7s-user"></i>
                            Suggestion
                        </router-link>
                    </li>

                    <li class="app-sidebar__heading">Profile</li>
                    <li>
                        <router-link ref="bouton12" :to="{name:'ProfileEmploye'} " :class="{'mm-active': boutonActif==12}" @click="selectBtn(12)">
                            <i class="metismenu-icon pe-7s-user"></i>
                            {{ user.nom  }}
                        </router-link>
                    </li>
                    
                </ul>
            </div>
        </div>
    </div>  

</template>

<script>
import store from '@/store'
export default {
name:"SideBar",
data() {
    return {
        user:'',
        boutonActif:null
    }
},
methods: {
    selectBtn(index){
        this.boutonActif=index;
    }
},
mounted() {
        this.user = store.getters.getUser;
        
    },
}
</script>