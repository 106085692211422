<template>
    <div class="app-wrapper-footer">
        <div class="app-footer">
            <div class="app-footer__inner">
                <div class="app-footer-left">
                    <ul class="nav">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <strong class="text-center">Copyright &copy; 2022-2023   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span href="https://adminlte.io">KapitalHumainConseils</span> </strong>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tous droit réserver
                            </a>
                        </li>
                        
                    </ul>
                </div>
                <div class="app-footer-right">
                    <ul class="nav">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <b>Version &nbsp;</b> 1.0.0
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
export default {

}
</script>

<style>

</style>