<template>
    <Teleport to="body">
  <div v-if="visible" class="modal">
       <div class="modal-content">
        <button class="btn btn-danger fermer col-1" v-on:click="visible=false">X</button>
       
            <modalGraph :donnes="donnes" :labels="labels" :titre="titre" ></modalGraph>

       
        </div>
  </div>
 </Teleport>
    <table id="telecharger" class="table table-striped">
        <thead>
        <tr v-if="this.performances.length==2||this.performances.length==3">
            <th>ID</th>
            <th>Indicateur</th>
            <th>Valeur</th>
            <th>Interpretation</th>
            <th>Action</th>
        </tr>
        </thead>                                                                                                                                                                                
        <tbody>
        <tr v-for="item in ratios" :key="item.id">
            <td> {{ item.id }} </td>
            <td>{{ item.indicateur.libelle }}</td>
            <td> {{ item.valeur }} </td>
            <td>{{item.interpretation}}</td>
            <td><button v-on:click="fetchData(item.indicateur_id,item.indicateur.libelle)" class="btn btn-primary" title="Voir l'evolution">Evolution</button></td>
        </tr>
        </tbody>
    </table>
    <div id="rapport" v-if="this.performances.length==1|| this.performances.length==2||this.performances.length==3">
        <h1 v-if="this.performances.length==1|| this.performances.length==2">Rapport d'evaluation Financière de l'entreprise {{this.user.denomination}}</h1>
        <div v-html="this.performanceWithStyle"></div>
        <h2 v-if="this.performances.length==2||this.performances.length==3">Evolution des Indicateurs Clés</h2>
        <table class="table table-striped" id="tableau">
            <thead>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <th></th>
                    <th v-if="this.performances.length==3">{{this.performances[2].exercice}}</th>
                    <th v-if="this.performances.length==2||this.performances.length==3">{{this.performances[1].exercice}}</th>
                    <th v-if="this.performances.length==2||this.performances.length==3">{{this.performances[0].exercice}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td scope="col">Besoin en fond de roulement</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[14].valeur}}</td>
                    <td >{{this.ratios1[14].valeur}}</td>
                    <td >{{this.ratios[14].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td>Fond de roulement</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[15].valeur}}</td>
                    <td >{{this.ratios1[15].valeur}}</td>
                    <td >{{this.ratios[15].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td >Tresoreirie Nette</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[16].valeur}}</td>
                    <td >{{this.ratios1[16].valeur}}</td>
                    <td >{{this.ratios[16].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td>Delai de rotation des creances</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[12].valeur}}</td>
                    <td >{{this.ratios1[12].valeur}}</td>
                    <td >{{this.ratios[12].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td>Delai de rotation des dettes</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[13].valeur}}</td>
                    <td >{{this.ratios1[13].valeur}}</td>
                    <td >{{this.ratios[13].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td>Delai de rotation des stocks</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[11].valeur}}</td>
                    <td >{{this.ratios1[11].valeur}}</td>
                    <td >{{this.ratios[11].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td>Rentabilité Economique</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[21].valeur}}</td>
                    <td >{{this.ratios1[21].valeur}}</td>
                    <td >{{this.ratios[21].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td>Rentabilité des Capitaux Propres</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[20].valeur}}</td>
                    <td >{{this.ratios1[20].valeur}}</td>
                    <td >{{this.ratios[20].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td>Marge brute sur Chiffre d'affaire</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[25].valeur}}</td>
                    <td >{{this.ratios1[25].valeur}}</td>
                    <td >{{this.ratios[25].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td>Marge nette sur Chiffre d'affaire</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[26].valeur}}</td>
                    <td >{{this.ratios1[26].valeur}}</td>
                    <td >{{this.ratios[26].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td>Endettement total</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[8].valeur}}</td>
                    <td >{{this.ratios1[8].valeur}}</td>
                    <td >{{this.ratios[8].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td>Endettement à long terme</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[9].valeur}}</td>
                    <td >{{this.ratios1[9].valeur}}</td>
                    <td >{{this.ratios[9].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td>Liquidité Generale</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[5].valeur}}</td>
                    <td >{{this.ratios1[5].valeur}}</td>
                    <td >{{this.ratios[5].valeur}}</td>
                </tr>
                <tr v-if="this.performances.length==2||this.performances.length==3">
                    <td>Liquidité immediate</td>
                    <td v-if="this.performances.length==3">{{this.ratios2[6].valeur}}</td>
                    <td >{{this.ratios1[6].valeur}}</td>
                    <td >{{this.ratios[6].valeur}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import modalGraph from './ModalChart.vue'
    import store from '@/store';
    import { performance } from '@/services';
   
    export default {
  watch: {
  },
        props: ['id'],
        data() {
            return {
                user: store.getters.getUser,
                ratios: [],
                ratios1:[],
                ratios2:[],
                performances:[],
                graphVal:[],
                visible:false,
                donnes:[],
                labels:[],
                titre:''
                
            }
        },
        methods:{
            fetchData(val1,val2) {
                performance.fetchDatas(val1).then((response) => {   
                // Traitez la réponse de l'API ici
             
                console.log(response.data.donnes);
                 console.log(response.data.labels);
                this.labels=response.data.labels
              this.donnes=response.data.donnes
               this.visible=true,
               this.titre=val2
               console.log(this.labels);
              console.log(this.donnes);
                })
                .catch(error => {
                // Gérez les erreurs ici
                console.error(error);
                });
               
            }
        },
        mounted(){
            performance.getIndicateurs(this.id).then((response) => {
                this.ratios = response.data.ratios,
                this.ratios1=response.data.ratios1,
                this.ratios2=response.data.ratios2,
                this.performances=response.data.performances
                console.log(this.performances.length)
            })

        },
       
        components:{
            modalGraph
        },
        computed: {
        performanceWithStyle() {
            // Ajoutez des classes CSS aux balises <p> et <h1> dans le texte.
            const performanceText = this.performances[0].performanceglobale;
            const styledPerformanceText = performanceText
                .replace(/<p>/g, '<p style="text-align:justify;font-size:15px;">')
                .replace(/<h1>/g, '<h1 style="font-size:25px;text-align:center; font-weight:bold;font-weight:bold;background-color:#1d4852;color:#fff">')
                .replace(/<h3>/g, '<h3 style="font-size:20px;text-align:center;background-color:#1d4852;color:#fff">')
                .replace(/<h2>/g, '<h2 style="font-size:20px;text-align:center;font-weight:bold;background-color:#1d4852;color:#fff">');
            return styledPerformanceText;
        },
    },
    }
</script>
<style scoped>
    .modal{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: rgba(0,0,0,0.9);
  display:flex;
  justify-content:center;
  align-items:center;
 }

 .modal-content{
  background-color:#fff;
  width:50%;
  padding:20px;
  border-radius:5px;
 }

 .modal-footer{
  text-align:right;
  margin-top:10px;
 }

 button{
  padding:5px;
  
  border:none;
  border-radius:3px;
  cursor:pointer;
 }
 .fermer{
    position:absolute;
    top:0;
    right:0;
 }
 tr{
    text-align: center;
 }
 h1{
    font-size:25px;
    text-align: center;
    color:#fff;
    background-color: #1d4852;
    font-weight:bold;
 }

 h2{
    font-size:20px;
    text-align: center;
    color:#fff;
    background-color: #1d4852;
    font-weight:bold;
 }
 .texte{
    font-size:5px;
    text-align: center;
    color: blue;
 }
 div{
    text-align: justify;
 }
</style>