

import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

// eslint-disable-next-line no-unused-vars
const tempsExpiration =  1000 

// Create a new store instance.
const store = createStore({
    plugins: [
        createPersistedState({
            storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
            },
        }),
        ],
    state () {
        return {
            token: null,
            type: null,
            user: null
        }
    },
    getters:{
        getToken(state){
            return state.token
        },
        getType(state){
            return state.type
        },
        getUser(state){
            return state.user
        },
        isConnect(state){
            if (state.user && state.token && state.type) {
                return state.type
            }else{
                return null
            }
        }
    },
    mutations: {
        setToken(state, token){
            state.token = token
        },
        setType(state, type){
            state.type = type
        },
        setUser(state, user){
            state.user = user
        },
        logout(state){
            state.token = null
            state.user = null
            state.type = null
        }
    }
})

// eslint-disable-next-line no-unused-vars
const persistedStatePlugin = store._mutations["vuex-persistedstate:plugin"];
// setTimeout(() => {
    
//     persistedStatePlugin.forEach((mutation) => {
//       mutation[0]({}); // Effacer les données persistantes en exécutant la mutation
//     });
// }, tempsExpiration);

export default store